import Select from "react-select";
import { styled } from "styled-components";
import { MdClose } from 'react-icons/md'
import theme from '../../../../styles/theme'

export const StyledDivChangeTask = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 32px;
  .styledForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .formControl {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding-bottom: 26px;

      gap: 10px;

      width: 100%;

    }

    input,
    select {
      padding: 8px;
      font-size: 1.6rem;
      border-radius: 5px;
    }
  }
  .divForm {
    background-color: var(--white);
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    border-radius: 8px;

    width: 600px;
    height: 500px;
  }
  .divBtn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    margin-top: 30px;

    button {
      width: 120px;
      background-color: var(--color5);
      padding: 12px;
      border-radius: 8px;
      color: var(--white);
    }
  }

  h2 {
    color: ${theme.colors.textSidebar};
    font-family: Encode Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

    .divBtns{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    .buttonRegister {
        background-color: var(--green-1);
        padding: 12px;
        border-radius: 8px;
        color: var(--white);
        margin-top: 8px;
        width: 35%;
      }

    .buttonCancel {
      border: none;
      background-color: ${theme.colors.white};
      color: ${theme.colors.textSidebar};
      width: 35%;
    }
  }

`;

export const StyledSelect = styled(Select)`
  width: 100%;
  font-size: 14px;
  .Select__control {
    border: none; // Remove a borda do controle, se necessário
    box-shadow: none; // Remove a sombra do controle, se necessário
  }

  .Select__indicators {
    display: none; // Oculta o contêiner de indicadores
  }
  .Select__dropdown-indicator {
    display: none;
  }
`;

export const StyledTr = styled.tr`
  margin-top: 10px;
  border-radius: 4px;
  border: 0.5px solid ${theme.colors.blackStrong};
  width: 100%;
  font-size: 12px;
  th:first-child {
    border-radius: 4px 0 0 0;
  }
  th:last-child {
    border-radius: 0 4px 0 0;
  }

  td:last-child {
    border-radius: 0 0 4px 0;
  }
  td:first-child {
    border-radius: 0 0 0 4px;
  }
  
  th {
    background-color: ${theme.colors.backgroundLightShade};
    color: ${theme.colors.textSidebar};
    border-bottom: 0.5px solid ${theme.colors.blackStrong};

  }

  td {
    background-color: ${theme.colors.white};
    width: 10%;
  }

  th,
  td {
    text-align: center;
    padding: 4px;
  }
  td {
    height: 20px;
  }
  .dsApontamento {
    width: 50%;
  }
`;

//stilização header modal
export const ContainerClose = styled.div`
  cursor: pointer;
`
export const Header = styled.div`
  width: 100%;
  padding: 12px 30px;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 1px 16px 0px rgba(227, 227, 227, 0.50);
`

export const ContentDescriptionHeader = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`

export const BoxIconRotation = styled.div`
  background-color: ${props =>
    props.setBackgroundColor ? props.setBackgroundColor : theme.colors.info400};
  height: 36px;
  width: 36px;
  border-radius: 4px;

  transform: rotate(20deg);
  margin-right: 1rem;
`

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 8px;
  left: 22px;
  top: 16px;
  backdrop-filter: blur(8px);
  border: 2px solid #fff;
`
export const IconClose = styled(MdClose)`
  font-size: 24px;
  color: ${theme.colors.textSidebar};
`
export const TextHeader = styled.h3`
  font-weight: 600;
  color: #787878;
  font-size: 16px;
`
