import Select from "react-select";
import styled from "styled-components";
import theme from "../../styles/theme";

export const StyledApontamento = styled.div`
  background-color: ${theme.colors.backgroundLight};
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .header-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 48px;

    .btnTask {
      background-color: ${theme.colors.secondary};
      padding: 12px 24px;
      border-radius: 8px;
      color: var(--white);
    }
  }

  .container {
    display: flex;
    align-items: flex-start;
    /* justify-content: space-around; */
    column-gap: 32px;
    padding-bottom: 48px;
    width: 100%;
  }

  .styledForm {
    width: 100%;
    background-color: ${theme.colors.backgroundLight};

    label {
      color: var(--grey50);
    }

    .formControl {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      gap: 10px;

      input,
      textarea,
      select {
        margin-top: 5px;
        margin-bottom: 8px;
      }
    }

    .divBothHours {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5%;

      width: 100%;
    }

    .buttonRegister {
      background-color: var(--green-1);
      padding: 12px;
      border-radius: 8px;
      color: var(--white);
      margin-top: 8px;
    }

    .divBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .divSwitchLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;

        .labelSwitch {
          color: ${(props) =>
            props.labelColor ? theme.colors.pink500 : theme.colors.textSidebar};
        }
      }
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #eee;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      & input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      & .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .divForm {
    /* background-color: var(--white); */
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    border-radius: 8px;

    width: 600px;
    height: fit-content;
    /* padding: 24px 48px; */
  }

  .listaApt {
    margin-bottom: auto;
  }

  @media (width < 1300px) {
    .container {
      flex-direction: column;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  .Select__control {
    border: none; // Remove a borda do controle, se necessário
    box-shadow: none; // Remove a sombra do controle, se necessário
  }

  .Select__indicators {
    display: none; // Oculta o contêiner de indicadores
  }
  .Select__dropdown-indicator {
    display: none;
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  padding: 8px;
`;

export const TextIsRetrabalho = styled.p`
  white-space: nowrap;
  color: ${({ isRetrabalho, is_ausente }) =>
    isRetrabalho
      ? theme.colors.pink500
      : is_ausente
      ? theme.colors.warning
      : theme.colors.textSidebar};
`;

export const TableDivAcoes = styled.div`
  display: flex;
  gap: 0.8rem;
  justify-content: ${(props) => (props.is_ausente ? "end" : "start")};
`;

export const FirstContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 3rem;
  align-items: center;

  .container-switch {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    gap: 1rem;
  }
`;
