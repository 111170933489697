import { styled } from "styled-components";
import { MdClose } from 'react-icons/md'
import theme from '../../../../styles/theme'

export const StyledModalDelApt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
  height: 100%;
  background-color: ${theme.colors.white};
  h2 {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: ${theme.colors.textSidebar};
  }

  .divBtns{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    .buttonRegister {
        background-color: var(--green-1);
        padding: 12px;
        border-radius: 8px;
        color: var(--white);
        margin-top: 8px;
        width: 35%;
      }

    .buttonCancel {
      border: none;
      background-color: ${theme.colors.white};
      color: ${theme.colors.textSidebar};
      width: 35%;
    }
  }
`;

export const ContainerClose = styled.div`
  cursor: pointer;
`

export const TextClose = styled.span`
  color: ${theme.colors.secondary};
  text-transform: uppercase;
  font-size: 1.2rem;
`

export const Line = styled.div`
  border-bottom: 2px solid ${theme.colors.textLight}55;
  margin-bottom: 3rem;
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const TextDescription = styled.span`
  color: ${theme.colors.textLight};
  margin-left: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
`

export const TextTitle = styled.h1`
  font-size: 24px;
  color: #8c8c8c;
  font-weight: 600;
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`

export const Header = styled.div`
  width: 100%;
  padding: 12px 30px;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 1px 16px 0px rgba(227, 227, 227, 0.50);
`

export const ContentDescriptionHeader = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`

export const BoxIconRotation = styled.div`
  background-color: ${props =>
    props.setBackgroundColor ? props.setBackgroundColor : theme.colors.info400};
  height: 36px;
  width: 36px;
  border-radius: 4px;

  transform: rotate(20deg);
  margin-right: 1rem;
`

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 8px;
  left: 22px;
  top: 16px;
  backdrop-filter: blur(8px);
  border: 2px solid #fff;
`
export const IconClose = styled(MdClose)`
  font-size: 24px;
  color: ${theme.colors.textSidebar};
`
export const TextHeader = styled.h3`
  font-weight: 600;
  color: #787878;  
  font-size: 16px;
`
