import {GiCardExchange} from "react-icons/gi";
import {BiEdit} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import {StyledTr} from "./style";
import {useContext, useState} from "react";
import {ModalEditAptContext} from "../../../providers/ModalEditApt/ModalEditApt";

const AptTableRow = ({apt, setSelectedApt, setOpenChildModal}) => {
  const [hovered, setHovered] = useState(null);

  const { setOpenModalChangeTask} =
    useContext(ModalEditAptContext);
  const handleMouseEnter = (className) => {
    setHovered(className);
  };
  const handleMouseLeave = () => {
    setHovered(null); // Remove a classe hoverizada ao sair
  };
  const isHovered = (className) => {
    return hovered === className ? "hovered" : "";
  };
  return (
    <StyledTr is_retrabalho={apt.is_retrabalho}>
      <td
        className={`td1 tdJira ${isHovered("td1")}`}
        onMouseEnter={() => handleMouseEnter("td1")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setSelectedApt(apt);
          setOpenModalChangeTask(true);
        }}
      >
        {apt.task_key}
      </td>
      <td
        className={`td2 tdDescription ${isHovered("td2")}`}
        onMouseEnter={() => handleMouseEnter("td2")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setSelectedApt(apt);
          setOpenChildModal(true);
        }}
      >
        {apt.ds_apontamento.length > 40
          ? apt.ds_apontamento.slice(0, 40) + "..."
          : apt.ds_apontamento}
      </td>
      <td
        className={`td2 tdDescription ${isHovered("td2")}`}
        onMouseEnter={() => handleMouseEnter("td2")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setSelectedApt(apt);
          setOpenChildModal(true);
        }}
      >
        {apt.hr_inicio}
      </td>
      <td
        className={`td2 tdDescription ${isHovered("td2")}`}
        onMouseEnter={() => handleMouseEnter("td2")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setSelectedApt(apt);
          setOpenChildModal(true);
        }}
      >
        {apt.hr_fim}
      </td>
      <td
        className={`td2 tdDescription ${isHovered("td2")}`}
        onMouseEnter={() => handleMouseEnter("td2")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          setSelectedApt(apt);
          setOpenChildModal(true);
        }}
      >
        {parseFloat(apt.total_horas).toFixed(2)}
      </td>
    </StyledTr>
  );
};

export default AptTableRow;
