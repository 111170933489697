import React, { useContext, useState } from "react";
import {
  BoxIcon,
  ButtonIcon,
  DivAcoesTable,
  DivBtnExport,
  DivTotalHoras,
  StyledControleHoras,
} from "./style";
import { AptContext } from "../../providers/AptContext/AptContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loadApts from "../../utils/loadApts";
import ModalEditApt from "../../components/Modals/ModalEditApt";
import { filterAptsMes } from "../../utils/filterApts";
import api from "../../services/api";
import ModalExportExcel from "../../components/Modals/ModalExportExcel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { clearUser } from "../../utils/clearUser";
import ModalExportRda from "../../components/Modals/ModalExportRda";
import Layout from "../_layout";
import { LabeledStyledSelect } from "../../components/StyledSelect";
import { FaCaretDown, FaEdit, FaFileExcel, FaTrashAlt } from "react-icons/fa";
import { GiCardExchange } from "react-icons/gi";
import sumHoursByDay from "../../utils/sumHoursByDay";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import theme from "../../styles/theme";
import { ModalEditAptContext } from "../../providers/ModalEditApt/ModalEditApt";
import ModalBloqueioDataRetroativa from "../../components/Modals/ModalBloqueioDataRetroativo";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { messageError } from "../../utils/message";

const ControleHoras = () => {
  const {
    apt,
    setApt,
    apontamentosMes,
    setApontamentosMes,
    mes,
    setMes,
    ano,
    setAno,
    user,
  } = useContext(AptContext);

  const token = localStorage.getItem("@token");

  const idEmpresa = process.env.REACT_APP_ID_EMPRESA;

  const navigate = useNavigate();

  const {
    setOpenModalChangeTask,
    setSelectedApt,
    setOpenChildModal,
    setIdApt,
    setOpenModalDelApt,
  } = useContext(ModalEditAptContext);

  const [users, setUsers] = useState([]);
  const [userSelecionado, setUserSelecionado] = useState(user.id);
  const [usersOptions, setUsersOptions] = useState([]);
  const [openModalExport, setOpenModalExport] = useState(false);
  const [openModalExportRda, setOpenModalExportRda] = useState(false);
  const [dataBloqueio, setDataBloqueio] = useState("");
  const [arrayOpenClose, setarrayOpenClose] = useState([]);
  const [openModalBloqueioDataRetroativa, setOpenModalBloqueioDataRetroativa] =
    useState(false);
  const [datasComFilhos, setDatasComFilhos] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const mesReferencia = [
    { label: "Janeiro", value: "0" },
    { label: "Fevereiro", value: "1" },
    { label: "Março", value: "2" },
    { label: "Abril", value: "3" },
    { label: "Maio", value: "4" },
    { label: "Junho", value: "5" },
    { label: "Julho", value: "6" },
    { label: "Agosto", value: "7" },
    { label: "Setembro", value: "8" },
    { label: "Outubro", value: "9" },
    { label: "Novembro", value: "10" },
    { label: "Dezembro", value: "11" },
  ];

  // useEffect(() => {
  //   const filteredApts = filterAptsMes(apt, mes, ano);
  //   setApontamentosMes(filteredApts);
  // }, [mes, ano]);

  const handleDateMoth = () => {
    const filteredApts = filterAptsMes(apt, mes.value, ano.value);

    setApontamentosMes(filteredApts);
  };

  const handleUser = (user) => {
    setUserSelecionado(user);
    setLoadingUsers(true);

    api
      .get(`/apontamentos/${user.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setApt(resp.data.data);
      })
      .catch((err) => {
        if (
          JSON.parse(err.request.response).Erro == "Token expirado ou inválido"
        ) {
          clearUser(navigate);
        } else {
          messageError(JSON.parse(err.request.response).Erro);
        }
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const formSchema = yup.object().shape({
    data_bloqueio_lancamento: yup.date().required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleButtonClick = () => {
    setOpenModalBloqueioDataRetroativa(true);
  };

  function handleCollapseRowInicial(data) {
    if (!arrayOpenClose.includes(data)) {
      const newCd = data;
      const newArrayCd = [...arrayOpenClose, newCd];
      setarrayOpenClose(newArrayCd);
    } else {
      const newArrayCd = arrayOpenClose.filter((dia) => dia !== data);
      setarrayOpenClose(newArrayCd);
    }
  }

  function renderFilho(arrayFilho, index) {
    const font = 14;
    return (
      <>
        <TableRow key={"header" + index}>
          <TableCell
            key={"header task" + index}
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {"Tarefa"}
          </TableCell>
          <TableCell
            key={"header descri" + index}
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {"Descrição"}
          </TableCell>
          <TableCell
            key={"header h_inicio" + index}
            className={classes.centralizadoCel}
            style={{
              fontWeight: "600",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {"Horário de Início"}
          </TableCell>
          <TableCell
            key={"header h_fim" + index}
            className={classes.centralizadoCel}
            style={{
              fontWeight: "600",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {"Horário de Término"}
          </TableCell>
          <TableCell
            key={"header horas" + index}
            className={classes.centralizadoCel}
            style={{
              fontWeight: "600",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {"Horas"}
          </TableCell>
          <TableCell
            key={"header acao" + index}
            className={classes.centralizadoCel}
            style={{
              fontWeight: "600",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {"Ações"}
          </TableCell>
        </TableRow>
        {arrayFilho.map((apontamento) => (
          <TableRow
            key={apontamento.id + "row"}
            style={{
              color: "red",
            }}
          >
            <TableCell
              key={apontamento.id + "task"}
              className={classes.esperadoCel}
              style={{
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {apontamento.is_ausente ? "-" : apontamento.task_key}
            </TableCell>
            <TableCell
              key={apontamento.id + "descricao"}
              className={classes.esperadoCel}
              style={{
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {apontamento.ds_apontamento.length > 40
                ? apontamento.ds_apontamento.slice(0, 40) + "..."
                : apontamento.ds_apontamento}
            </TableCell>
            <TableCell
              key={apontamento.id + "h_inicio"}
              className={classes.centralizadoCel}
              style={{
                fontWeight: "400",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {apontamento.is_ausente ? "-" : apontamento.hr_inicio}
            </TableCell>
            <TableCell
              key={apontamento.id + "h_fim"}
              className={classes.centralizadoCel}
              style={{
                fontWeight: "400",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {apontamento.is_ausente ? "-" : apontamento.hr_fim}
            </TableCell>
            <TableCell
              key={apontamento.id + "h_total"}
              className={classes.centralizadoCel}
              style={{
                fontWeight: "400",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {apontamento.is_ausente
                ? "-"
                : Number(apontamento.total_horas).toFixed(2)}
            </TableCell>
            <TableCell
              key={apontamento.id + "acoes"}
              className={classes.centralizadoCel}
              style={{
                fontWeight: "400",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              <DivAcoesTable>
                {/* <div>
                    <Button
                      value={apontamento}
                      id="fade-button"
                      aria-controls={open ? 'fade-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      disableRipple
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        "&.MuiButtonBase-root:hover": {
                          opacity: '1.3',
                          transition: 'background-color 0.2s',
                          transition:' all 0.1s ease-in-out',
                          transform: 'scale(1.05)',
                          bgcolor: "transparent"
                        }
                      }}
                    >
                      <FaEdit color={theme.colors.warning} size={18}/>
                      
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem 
                        onClick={()=>{
                        // Aqui o bug que não pega a task que esta na linha 
                        setSelectedApt(apontamento)
                        setOpenModalChangeTask(true)
                        }}
                        sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '400',
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        color: theme.colors.textSidebar,
                        ':hover': {
                          backgroundColor: theme.colors.warning + '1A',
                          color: theme.colors.warning
                        }
                      }}
                      ><FaEdit/>Editar projeto/tarefa </MenuItem>
                      <MenuItem 
                      onClick={ ()=> {
                        setSelectedApt(apontamento)
                        setOpenChildModal(true)
                      }
                      }
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: '400',
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        color: theme.colors.textSidebar,
                        ':hover': {
                          backgroundColor: theme.colors.secondary + '1A',
                          color: theme.colors.secondary
                        }
                      }}
                      ><FaArchive/>Editar dados gerais </MenuItem>
                    </Menu>

                  {/* <ButtonIcon>
                  </ButtonIcon> */}
                {/* </div> */}
                {!apontamento.is_ausente && (
                  <>
                    <ButtonIcon
                      onClick={() => {
                        setSelectedApt(apontamento);
                        setOpenModalChangeTask(true);
                      }}
                    >
                      <GiCardExchange color={theme.colors.warning} size={18} />
                    </ButtonIcon>
                    <ButtonIcon
                      onClick={() => {
                        setSelectedApt(apontamento);
                        setOpenChildModal(true);
                      }}
                    >
                      <FaEdit color={theme.colors.secondary} size={18} />
                    </ButtonIcon>
                  </>
                )}

                <ButtonIcon
                  onClick={() => {
                    setIdApt(apontamento.id);
                    setOpenModalDelApt(true);
                  }}
                >
                  <FaTrashAlt color={theme.colors.error} size={18} />
                </ButtonIcon>
              </DivAcoesTable>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  const classes = useStyles();

  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  useEffect(() => {
    const today = new Date();

    if (!mes.value) {
      const mesExtenso = today.toLocaleString("pt-BR", { month: "long" });
      const mesExtensoTratado =
        mesExtenso.charAt(0).toUpperCase() + mesExtenso.slice(1);

      setMes({ label: mesExtensoTratado, value: today.getMonth() });
    }

    if (!ano.value) {
      setAno({ label: today.getFullYear(), value: today.getFullYear() });
    }

    if (!token) {
      navigate("/");
    } else {
      const loadAptsData = async () => {
        const apts = await loadApts(token);
        setApt(apts);
        const filteredApts = filterAptsMes(apts, mes, ano);
        setApontamentosMes(filteredApts);
      };
      loadAptsData();
    }
  }, []);

  useEffect(() => {
    if (user.is_adm) {
      const getDataBloqueio = async () => {
        await api
          .get(`/empresa/${idEmpresa}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((resp) => {
            setDataBloqueio(
              resp.data.data.data_bloqueio_lancamento
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            );
          })
          .catch((err) => {
            if (
              JSON.parse(err.request.response).Erro ==
              "Token expirado ou inválido"
            ) {
              clearUser(navigate);
            } else {
              messageError(JSON.parse(err.request.response).Erro);
            }
          });
      };

      api
        .get(`/empresa/${idEmpresa}/operador`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          setUsers(resp.data.data);
        })
        .catch((err) => {
          if (
            JSON.parse(err.request.response).Erro ==
            "Token expirado ou inválido"
          ) {
            clearUser(navigate);
          } else {
            messageError(JSON.parse(err.request.response).Erro);
          }
        });

      getDataBloqueio();
    }
  }, [user]);

  useEffect(() => {
    const options = users.map((user) => {
      const nameSplit = user.nm_operador.split(" ");
      var nameUser = "";
      nameSplit.length > 1
        ? (nameUser = `${nameSplit[0]} ${nameSplit[1]}`)
        : (nameUser = nameSplit[0]);

      return {
        value: user.id,
        label: nameUser,
      };
    });
    options.sort((a, b) => a.label.localeCompare(b.label));
    setUsersOptions(options);
  }, [users]);

  useEffect(() => {
    handleDateMoth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apt, mes, ano]);

  useEffect(() => {
    const horasPorDia = sumHoursByDay(apontamentosMes);

    const sortedRows = Object.entries(horasPorDia)
      .map(([data, horas]) => ({ data, horas }))
      .sort((a, b) => a.data.substring(0, 2) - b.data.substring(0, 2));
    // const rows = sortedRows.map(({data, horas}) => (
    //   <DataTableRow key={data} data={data} horas={horas} />
    // ));

    const dataComFilho = sortedRows.map((dia) => {
      return {
        data: dia.data,
        horas: dia.horas,
        apontamento: apontamentosMes.filter((apontamento) => {
          const data = new Date(apontamento.dt_apontamento);
          const diaGet = data.getDate();
          const mes = data.getMonth() + 1;
          const ano = data.getFullYear();
          const dataFormatada = `${diaGet.toString().padStart(2, "0")}/${mes
            .toString()
            .padStart(2, "0")}/${ano.toString()}`;

          if (dataFormatada == dia.data) {
            return apontamento;
          }
        }),
      };
    });
    setDatasComFilhos(dataComFilho);
  }, [apontamentosMes]);

  return (
    <Layout>
      <ModalEditApt />
      <ModalBloqueioDataRetroativa
        openModalBloqueioDataRetroativa={openModalBloqueioDataRetroativa}
        setOpenModalBloqueioDataRetroativa={setOpenModalBloqueioDataRetroativa}
        setDataBloqueio={setDataBloqueio}
        dataBloqueio={dataBloqueio}
      />
      <ModalExportExcel
        openModalExport={openModalExport}
        setOpenModalExport={setOpenModalExport}
      />
      <ModalExportRda
        openModalExport={openModalExportRda}
        setOpenModalExport={setOpenModalExportRda}
      />
      <StyledControleHoras>
        <div className="container">
          <div className="divAdm">
            <h1>Controle de Horas</h1>
            {user.is_adm && (
              <form
                className="divDataBloqueio"
                onSubmit={handleSubmit(handleButtonClick)}
              >
                <p className="textDataBloqueio">Bloqueio Retroativo: </p>
                <p className="textDataBloqueioBold">{dataBloqueio}</p>
                <button className="btnEditBloqueio" onClick={handleButtonClick}>
                  <FaEdit size={22} onClick={handleButtonClick} />
                </button>
              </form>
            )}
          </div>

          <div className="dataHora">
            {user.is_adm && (
              <LabeledStyledSelect
                label={"Colaborador"}
                id="selectUser"
                placeholder="Selecione um Colaborador"
                value={userSelecionado}
                onChange={handleUser}
                options={usersOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: "6px 0px",
                  }),
                }}
              />
            )}
            <LabeledStyledSelect
              label={"Mês de Referência"}
              id="selectMes"
              placeholder="Selecione um Mês"
              value={mes}
              onChange={(e) => {
                setMes(e);
              }}
              options={mesReferencia}
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "6px 0px",
                }),
              }}
            />

            <LabeledStyledSelect
              label={"Ano de Referência"}
              id="selectAno"
              placeholder="Selecione um Ano"
              value={ano}
              onChange={(e) => {
                setAno(e);
              }}
              options={[
                { label: "2022", value: 2022 },
                { label: "2023", value: 2023 },
                { label: "2024", value: 2024 },
              ]}
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "6px 0px",
                }),
              }}
            />
            {loadingUsers ? (
              <div>
                <ClipLoader
                  css={override}
                  size={30}
                  color={"#000000"}
                  loading={loadingUsers}
                />
              </div>
            ) : (
              <button onClick={() => handleDateMoth()} className="btnFiltrar">
                Filtrar
              </button>
            )}
          </div>
          {user.is_adm && (
            <DivBtnExport>
              <button
                className="buttonExportDados"
                onClick={() => setOpenModalExport(true)}
              >
                <div className="informationBtnExport">
                  <FaFileExcel
                    className="iconBtnExport"
                    onClick={() => setOpenModalExport(true)}
                    size={16}
                  />
                  Exportar Todos Dados
                </div>
              </button>
              <button
                className="buttonExportDados"
                onClick={() => setOpenModalExportRda(true)}
              >
                <div className="informationBtnExport">
                  <FaFileExcel
                    className="iconBtnExport"
                    onClick={() => setOpenModalExportRda(true)}
                    size={16}
                  />
                  Exportar RDA
                </div>
              </button>
            </DivBtnExport>
          )}
          {/* <div className="containerList">
              <HorasTable apontamentos={apontamentosMes} />
            </div> */}

          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{ marginTop: "32px", maxWidth: "100%" }}
          >
            <Table className={classes.table} key={"tableControleDeHoras"}>
              <TableBody>
                {datasComFilhos.map((dia, indexDate) => {
                  const diaApontamentoTratado = dia.apontamento.sort((a, b) => {
                    const [aHour, aMinute] = a.hr_inicio.split(":");
                    const aDate = new Date(0, 0, 0, aHour, aMinute);

                    const [bHour, bMinute] = b.hr_inicio.split(":");
                    const bDate = new Date(0, 0, 0, bHour, bMinute);

                    return aDate - bDate;
                  });

                  return (
                    <>
                      <TableRow
                        key={dia.data}
                        style={{
                          backgroundColor: "#ebeeef",
                        }}
                      >
                        <TableCell
                          onClick={() => {
                            if (dia.apontamento.length > 0) {
                              handleCollapseRowInicial(dia.data);
                            }
                          }}
                          style={{
                            backgroundColor: "#ebeeef",
                            cursor: "pointer",
                            borderRight: "none",
                            fontSize: "14px",
                          }}
                          className={classes.fixedColumnBold}
                          colSpan={5}
                        >
                          <motion.div
                            transition={{ duration: 0.5 }}
                            exit={{ opacity: 0, height: 0 }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "12px",
                                alignItems: "center",
                                position: "sticky",
                                left: 0,
                                color: `${
                                  arrayOpenClose.includes(dia.data)
                                    ? "rgba(0, 122, 177, 1)"
                                    : "rgba(140, 140, 140, 1)"
                                }`,
                                fontWeight: `${
                                  arrayOpenClose.includes(dia.data)
                                    ? "500"
                                    : "400"
                                }`,
                              }}
                            >
                              <BoxIcon>
                                <FaCaretDown
                                  size={18}
                                  style={{
                                    transition: "all 0.5s ease",
                                    cursor: "pointer",
                                    transform: `rotate(${
                                      !arrayOpenClose.includes(dia.data)
                                        ? "-90deg"
                                        : "0turn"
                                    })`,
                                  }}
                                />
                              </BoxIcon>
                              {dia.data}
                            </div>
                          </motion.div>
                        </TableCell>
                        <TableCell
                          key={dia.data + dia.horas}
                          className={classes.esperadoCel}
                          colSpan={1}
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            color:
                              dia.horas.toFixed(2) <= 8.2 &&
                              dia.horas.toFixed(2) >= 7.8
                                ? theme.colors.primary
                                : dia.horas.toFixed(2) > 8.2
                                ? theme.colors.warning
                                : theme.colors.error,
                          }}
                        >
                          {"Horas Totais: " + dia.horas.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {dia.apontamento.length > 0 &&
                        arrayOpenClose.includes(dia.data) &&
                        renderFilho(diaApontamentoTratado, indexDate)}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <DivTotalHoras>
            <p>Horas totais</p>
            <p>
              {apontamentosMes
                .reduce((acc, act) => acc + Number(act.total_horas), 0)
                .toFixed(2)}
            </p>
          </DivTotalHoras>
        </div>
      </StyledControleHoras>
    </Layout>
  );
};

const useStyles = makeStyles((styled) =>
  createStyles({
    table: {
      width: "100%",
    },
    tableContainer: {
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#96BEFA",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#D9DCD6",
        borderRadius: "10px",
      },
    },
    initialCell: {
      fontSize: 32,
      textTransform: "capitalize",
      minWidth: 200,
      color: "rgba(140, 140, 140, 1)",
      border: "1px solid rgba(233, 233, 233, 1)",
      fontWeight: 400,
      // border: '1px solid #00000055'
    },
    monthsOfYearCell: {
      fontSize: 32,
      textTransform: "capitalize",
      width: "200px",
      border: "1px solid rgba(233, 233, 233, 1)",
      fontWeight: 500,
      color: "rgba(140, 140, 140, 1)",
    },
    monthsOfYearBackgoudCell: {
      fontSize: 32,
      textTransform: "capitalize",
      width: "200px",
      backgroundColor: "#F5F9FF",
      border: "1px solid rgba(233, 233, 233, 1)",
      fontWeight: 500,
      color: "rgba(140, 140, 140, 1)",
    },
    daysCell: {
      color: "#336EF0",
      fontWeight: 700,
      fontSize: "32px",
      border: "none",
      textAlign: "center",
    },
    esperadoCel: {
      color: "#8C8C8C",
      fontWeight: 700,
      fontSize: "1.6rem",
      border: "none",
      minWidth: "100px",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    centralizadoCel: {
      color: "#8C8C8C",
      fontWeight: 400,
      fontSize: "32px",
      border: "none",
      minWidth: "100px",
      display: "flex",
      justifyContent: "center",
      whiteSpace: "nowrap",
    },
    realizadoCel: {
      color: "#8C8C8C",
      fontWeight: 700,
      fontSize: "32px",
      border: "none",
      minWidth: "100px",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    avCel: {
      color: "#8C8C8C",
      fontWeight: 700,
      fontSize: "32px",
      border: "none",
      minWidth: "70px",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    ahCel: {
      color: "#8C8C8C",
      fontWeight: 900,
      fontSize: "32px",
      border: "none",
      minWidth: "70px",
      textAlign: "center",
      whiteSpace: "nowrap",
    },

    fixedColumnBold: {
      position: "sticky",
      left: 0,
      backgroundColor: "#ffffff",
      fontSize: 32,
      boxShadow: "5px 2px 5px grey",
      borderRight: "1px solid #8f8f8f55",
      fontWeight: 400,
      minWidth: 260,
      lineHeight: 1.2,

      "@media (max-width: 768px)": {
        minWidth: 150,
      },
    },
    fixedColumnRegular: {
      position: "sticky",
      left: 0,
      backgroundColor: "#ffffff",
      fontSize: 30,
      boxShadow: "5px 2px 5px grey",
      borderRight: "1px solid #8f8f8f55",
      minWidth: 250,

      "@media (max-width: 768px)": {
        minWidth: 150,
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: 32,
    },
    xCell: {
      backgroundColor: "#fff",
      fontSize: 32,
      textAlign: "left",
      fontWeight: 300,
      color: "rgba(140, 140, 140, 1)",
    },
    sticky: {
      position: "sticky",
      left: 0,
      backgroundColor: "#fff",
      fontSize: 30,
      textAlign: "left",
    },
    accountCell: {
      minWidth: 250,
      fontSize: 32,
      backgroundColor: "#fff",
      position: "sticky",
      left: 0,
      fontWeight: 300,
      color: "rgba(140, 140, 140, 1)",
      border: "1px solid rgba(233, 233, 233, 1)",
      paddingLeft: "35px",
      "@media (max-width: 768px)": {
        minWidth: 150,
      },
    },

    textBold: {
      fontWeight: "bold",
      fontSize: 30,
    },
    rows: {
      fontSize: 30,
    },
  })
);

export default ControleHoras;
