import React, { useEffect, useState } from "react";
import * as IconsAi from "react-icons/ai";
import * as IconsFa from "react-icons/fa";
import * as IconsIm from "react-icons/im";
import * as IconsTb from "react-icons/tb";

import {Link, useNavigate} from "react-router-dom";
// import { useRouter } from "next/router";

import {
  faAngleLeft,
  faAngleRight,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LinksSidebar } from "../../utils/LinksSidebar";
import { AptContext } from "../../providers/AptContext/AptContext";


import IconMenu from "../../assets/icons/menu.png";
import LogoMenu from "../../assets/images/logo-01.jpg";
import LogoMenuPhrase from "../../assets/images/logo-02.jpg";

import * as S from "./styles";

const Icon = ({ name }) => {
  const prefix = name.substring(0, 2);
  let IconComponent;
  if (prefix == "Im") {
    IconComponent = IconsIm[name];
  } else if (prefix == "Tb") {
    IconComponent = IconsTb[name];
  } else if (prefix == "Ai") {
    IconComponent = IconsAi[name];
  } else {
    IconComponent = IconsFa[name];
  }

  if (!IconComponent) {
    // Return a default one
    return <IconsFa.FaBeer />;
  }

  return <IconComponent />;
};

// interface SidebarProps {
//   hidden?: boolean;
//   menuExpanded?: boolean;
//   setMenuExpanded?: any;
// }

// export interface MenuItemProps {
//   icon?: string;
//   title: string;
//   InboxNumber?: string;
//   tooltip?: string;
//   path: string;
//   query?: any;
//   submenutype: string;
//   active?: boolean;
//   permission: string;
// }

// export interface MenuProps {
//   icon: string;
//   color: string;
//   title: string;
//   path?: string;
//   submenu: Array<MenuItemProps>;
// }

const Sidebar = ({
  hidden,
  menuExpanded,
  setMenuExpanded,
}) => {
  const [menus, setMenus] = useState([...LinksSidebar]);
  const [menuOption, setMenuOption] = useState([]);
  const [outrosArray, setOutrosArray] = useState([]);
  const [statusSubMenu, setStatusSubMenu] = useState(true);
  const [mobileStatus, setMobileStatus] = useState(false);
  const navigate = useNavigate();

  const { routesAllowed } = React.useContext(AptContext);

  const [routeSelected, setRouteSelected] = useState( );
  const [subMenuSelected] = useState();
  setMenuExpanded(false)

  // const filteredSideBar = LinksSidebar.map((item) => {
  //   if (user?.tctipo_usuario == "usuarioAdministrador") {
  //     return item.title == "Cadastros"
  //       ? {
  //           ...item,
  //           submenu: item.submenu.filter((subm) =>
  //             hasPermission(subm.permission)
  //           ),
  //         }
  //       : undefined;
  //   }
  //   return {
  //     ...item,
  //     submenu: item.submenu.filter((subm) => {
  //       return hasPermission(subm.permission);
  //     }),
  //   };
  // })
  //   .flat()
  //   .filter((x) => x);

  const toggleMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  const toggleMenuMobile = () => {
    setMenuExpanded(!menuExpanded);
    setStatusSubMenu(true);
  };

  const handleMouseEnter = (link) => {
    link.tooltipOn = true;
  };

  const handleMouseLeave = (link) => {
    link.tooltipOn = false;
  };

  function handleMenuOption(menuId, link) {
    const _menus = [...menus];
    const isAtive = _menus.some((value) => value.collapse === false);
    if (isAtive) {
      _menus.forEach((value, index) => {
        if (index !== menuId) {
          value.collapse = true;
        }
      });
    }
    _menus.forEach((value, index) => {
      if (index !== menuId) {
        value.selected = false;

        setRouteSelected("");
      } else {
        value.selected = true;
      }
    });
    const toggle = !_menus[menuId].collapse;
    _menus[menuId].collapse = toggle;
    setMenus(_menus);
    if (menuOption.title !== link.title) {
      setMenuOption(link);
      setStatusSubMenu(false);
    } else {
      setStatusSubMenu(!statusSubMenu);
    }

    setOutrosArray(link.submenu);
  }

  function getItemBoxStatus(path) {
    const routerSplit = window.location.pathname.split("/");
    const find =
      path &&
      window.location?.pathname?.search(path) >= 0 &&
      `/${routerSplit[1]}/${routerSplit[2]}` === path
        ? true
        : false;

    return find;
  }

  useEffect(() => {
    const sortedList = LinksSidebar?.sort((a, b) =>
      a?.title?.localeCompare(b.title)
    );

    sortedList.map((index) => {
      index?.submenu.sort((a, b) => a?.title?.localeCompare(b.title));
    });

    const _menus = sortedList?.map((value) => {
      const routerSplit = window.location.pathname.split("/");
      const pathFind = value?.submenu?.findIndex((value) => {
        if (
          value?.path &&
          window.location.pathname.search(value?.path) >= 0 &&
          `/${routerSplit[1]}/${routerSplit[2]}` === value?.path
        ) {
          value.active = true;
        }
        return (
          value?.path &&
          window.location.pathname.search(value?.path) >= 0 &&
          `/${routerSplit[1]}/${routerSplit[2]}` === value?.path
        );
      });

      return {
        collapse: false,
        active: pathFind >= 0 ? false : true,
        selected: false,
        tooltipOn: false,
      };
    });
    setMenus(_menus);
  }, [routesAllowed]);

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setMobileStatus(true);
    }
    if (window.innerWidth > 1000) {
      setMobileStatus(false);
    }
  }, []);

  return (
    <S.Container className="menu-open" expanded={menuExpanded} hidden={hidden}>
      <S.MenuIcon expanded={menuExpanded}>
        {!menuExpanded ? (
          <S.IconMenu>
            <S.ImgIcon src={IconMenu} onClick={toggleMenu} />
          </S.IconMenu>
        ) : (
          ""
        )}
      </S.MenuIcon>
      <S.Content expanded={menuExpanded} collapsed={statusSubMenu}>
        <S.LogoMenu expanded={menuExpanded}>
          <Link to={'/apontamento'}>
            <S.ImgLogoC
              src={LogoMenu}
              expanded={menuExpanded}
             
            />
          </Link>
          <Link to={`/apontamento`}>
            <S.ImgLogoOV src={LogoMenuPhrase} expanded={menuExpanded} />
          </Link>
        </S.LogoMenu>

        <S.StatusMenuButton collapsed={statusSubMenu} expanded={menuExpanded}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            onClick={() => (mobileStatus ? toggleMenuMobile() : toggleMenu())}
          />
          <FontAwesomeIcon
            icon={faAngleRight}
            onClick={() => toggleMenu()}
          />
        </S.StatusMenuButton>
        <S.LinksSideBar expanded={menuExpanded}>
          {LinksSidebar.map((link, index) => (
            <S.ItemList
              key={index}
              collapsed={menus[index]?.collapse}
              onClick={() => handleMenuOption(index, link)}
            >
              <S.ItemHeader alt={link.title} expanded={menuExpanded}>
                <S.ItemTitle
                  active={
                    menus[index]?.selected ||
                    link.submenu.some(
                      (x) => x.path.split("/")[1] == routeSelected
                    )
                  }
                  expanded={menuExpanded}
                  onMouseEnter={() => handleMouseEnter(link)}
                  onMouseLeave={() => handleMouseLeave(link)}
                >
                  <Icon name={link.icon} />
                  <S.TitleHolder
                    expanded={menuExpanded}
                    active={
                      menus[index]?.selected ||
                      link.submenu.some(
                        (x) => x.path.split("/")[1] == routeSelected
                      )
                    }
                  >
                    {link.title}{" "}
                    {/* <FontAwesomeIcon
                      className="seta"
                      icon={faAngleRight as any}
                    /> */}
                  </S.TitleHolder>
                </S.ItemTitle>
              </S.ItemHeader>
            </S.ItemList>
          ))}
        </S.LinksSideBar>
        <S.ItemList>
          <S.GearHolder expanded={menuExpanded} onClick={()=>{            localStorage.removeItem("@token");
            navigate("/");}}>
            <FontAwesomeIcon
              style={{ marginLeft: menuExpanded ? "1em" : "0" }}
              icon={faSignOut }
              onClick={   ()=> {
                localStorage.removeItem("@token");
                navigate("/");
                }       
              }
              color={'var(--textSidebar)'}
            />
            <S.GearText expanded={menuExpanded}>Sair</S.GearText>
          </S.GearHolder>
        </S.ItemList>
      </S.Content>
      <S.StatusSubMenuButton expanded={menuExpanded} collapsed={statusSubMenu}>
        <FontAwesomeIcon
          icon={faAngleLeft }
          onClick={() => setStatusSubMenu(!statusSubMenu)}
        />
      </S.StatusSubMenuButton>
      <S.BoxItems expanded={menuExpanded} collapsed={statusSubMenu}>
        <S.BackButtonMobile onClick={() => setStatusSubMenu(!statusSubMenu)}>
          Voltar
        </S.BackButtonMobile>
        <S.SubMenuTitle>{menuOption?.title}</S.SubMenuTitle>
        <S.CadastroBasico>
          {outrosArray?.map((subMenu, indexSub) =>
            !subMenu.path.startsWith("http") ? (
              <S.Link href={subMenu.path || ""} key={indexSub}>
                <S.ItemBox selected={getItemBoxStatus(subMenu.path)}>
                  <S.ItemIcon>
                    <S.ItemIconStatus></S.ItemIconStatus>
                  </S.ItemIcon>
                  <S.ItemText
                    active={subMenuSelected == subMenu.path.split("/")[1]}
                  >
                    {subMenu.title}
                  </S.ItemText>
                  {subMenu.InboxNumber && (
                    <S.ItemInboxNumber>{subMenu.InboxNumber}</S.ItemInboxNumber>
                  )}
                </S.ItemBox>
              </S.Link>
            ) : (
              <S.Link
                href={subMenu.path || ""}
                target="_blank"
                key={indexSub}
                rel="noreferrer"
              >
                <S.ItemBox selected={getItemBoxStatus(subMenu.path)}>
                  <S.ItemIcon>
                    <S.ItemIconStatus></S.ItemIconStatus>
                  </S.ItemIcon>
                  <S.ItemText>{subMenu.title}</S.ItemText>
                  {subMenu.InboxNumber && (
                    <S.ItemInboxNumber>{subMenu.InboxNumber}</S.ItemInboxNumber>
                  )}
                </S.ItemBox>
              </S.Link>
            )
          )}
        </S.CadastroBasico>
      </S.BoxItems>
    </S.Container>
  );
};

export default Sidebar;
