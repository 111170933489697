import {AptProviders} from "./AptContext/AptContext";
import {ModalEditAptProviders} from "./ModalEditApt/ModalEditApt";

const Providers = ({children}) => {
  return (
    <>
      <ModalEditAptProviders>
        <AptProviders>{children}</AptProviders>
      </ModalEditAptProviders>
    </>
  );
};

export default Providers;
