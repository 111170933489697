import React, { useEffect, useState } from 'react'
import * as Icons from 'react-icons/fa'

import * as S from './styles'

const ButtonTop = () => {
  const [topButton, setTopButton] = useState(false)

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setTopButton(true)
      } else {
        setTopButton(false)
      }
    })
  }, [])

  return (
    <div>
      {topButton && (
        <S.ButtonCustom onClick={scrollUp}>
          <Icons.FaArrowUp />
        </S.ButtonCustom>
      )}
    </div>
  )
}

export default ButtonTop
