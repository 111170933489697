import styled from "@emotion/styled";
import { MdClose } from 'react-icons/md'
import theme from '../../../styles/theme'

export const StyledModalEdit = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  /* padding-left: 60px; */
  gap: 20px;

  button {
    background-color: var(--color5);
    padding: 12px;
    border-radius: 8px;
    color: var(--white);
  }

  select {
    max-width: 80%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;

    width: 100%;
  }

  h2{
    background-color: var(--color3);
    padding: 5px;
    border-radius: 5px;
  }

  .divApts {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerContent = styled.div`
  height: 100%;
`

export const ContainerChildren = styled.div`
  height: '-10rem' ${props => (props.height ? props.height : '60rem')};
  overflow-y: auto;
`
export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.3em;
  /* padding-right: 3rem;
  padding-left: 3rem; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;

  .icon-close {
    cursor: pointer;
  }
`

export const ContainerClose = styled.div`
  cursor: pointer;
`

export const TextClose = styled.span`
  color: ${theme.colors.secondary};
  text-transform: uppercase;
  font-size: 1.2rem;
`

export const Line = styled.div`
  border-bottom: 2px solid ${theme.colors.textLight}55;
  margin-bottom: 3rem;
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const TextDescription = styled.span`
  color: ${theme.colors.textLight};
  margin-left: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
`

export const TextTitle = styled.h1`
  font-size: 24px;
  color: #8c8c8c;
  font-weight: 600;
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`

export const Header = styled.div`
  width: 100%;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  position: relative;
`

export const ContentDescriptionHeader = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`

export const BoxIconRotation = styled.div`
  background-color: ${props =>
    props.setBackgroundColor ? props.setBackgroundColor : theme.colors.info400};
  height: 36px;
  width: 36px;
  border-radius: 4px;

  transform: rotate(20deg);
  margin-right: 1rem;
`

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 8px;
  left: -10px;
  top: 8px;
  backdrop-filter: blur(8px);
  border: 2px solid #fff;
`
export const IconClose = styled(MdClose)`
  font-size: 24px;
  color: ${theme.colors.textSidebar};
`
export const TextHeader = styled.h3`
  font-weight: 600;
  color: #787878;
  font-size: 16px;
`
