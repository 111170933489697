import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../services/api.js";
import { useForm } from "react-hook-form";
import * as React from "react";

import { StyledModalBloqueioDataRetroativa } from "./style.js";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../../utils/clearUser.js";
import * as S from "./style.js";
import { FaEdit } from "react-icons/fa";
import theme from "../../../styles/theme.js";
import { LabeledStyledInputComponent } from "../../StyledInput/index.jsx";
import { messageError, messageSuccess } from "../../../utils/message.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 350,
  bgcolor: theme.colors.backgroundLight,
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const ModalBloqueioDataRetroativa = ({
  openModalBloqueioDataRetroativa,
  setOpenModalBloqueioDataRetroativa,
  setDataBloqueio,
  dataBloqueio,
}) => {
  const handleClose = () => setOpenModalBloqueioDataRetroativa(false);

  const token = localStorage.getItem("@token");
  const idEmpresa = process.env.REACT_APP_ID_EMPRESA;

  const formSchema = yup.object().shape({
    data_bloqueio_lancamento: yup.string().required(),
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleButtonClick = (data) => {
    if (data) {
      api
        .patch(`/empresa/${idEmpresa}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((_) => {
          const dataTratada = data.data_bloqueio_lancamento
            .split("-")
            .reverse()
            .join("/");
          setDataBloqueio(dataTratada);

          messageSuccess(`Data bloqueio alterada para ${dataTratada}`);
        })
        .catch((err) => {
          if (
            JSON.parse(err.request.response).Erro ==
            "Token expirado ou inválido"
          ) {
            clearUser(navigate);
          } else {
            messageError(JSON.parse(err.request.response).Erro);
          }
        });
    }
  };
  return (
    <div>
      <Modal
        open={openModalBloqueioDataRetroativa}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <StyledModalBloqueioDataRetroativa>
            <S.Header>
              <S.ContentDescriptionHeader>
                <S.BoxIconRotation
                  setBackgroundColor={theme.colors.warning200}
                ></S.BoxIconRotation>
                <S.BoxIcon>
                  <FaEdit color={theme.colors.warning} size={16} />
                </S.BoxIcon>
                <S.TextHeader>Editar Data de Bloqueio Retroativo</S.TextHeader>
              </S.ContentDescriptionHeader>
              <S.ContainerClose onClick={handleClose}>
                <S.IconClose />
              </S.ContainerClose>
            </S.Header>
            <form onSubmit={handleSubmit(handleButtonClick)}>
              <span>
                Altere a data de bloqueio retroativo no campo abaixo conforme
                necessidade.
              </span>
              <LabeledStyledInputComponent
                id="data_bloqueio_lancamento"
                name="data_bloqueio_lancamento"
                type="date"
                label="Data de Bloqueio"
                defaultValue={dataBloqueio}
                onChange={(e) =>
                  setValue("data_bloqueio_lancamento", e.target.value)
                }
                register={register("data_bloqueio_lancamento")}
              />
              <div className="divBtns">
                <button
                  type="button"
                  onClick={handleClose}
                  className="buttonCancel"
                >
                  Cancelar
                </button>
                <button type="submit" className="buttonRegister">
                  Salvar
                </button>
              </div>
            </form>
          </StyledModalBloqueioDataRetroativa>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalBloqueioDataRetroativa;
