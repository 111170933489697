import api from "../services/api";

const loadApts = async (token) => {
  if (token) {
    const userdata = JSON.parse(localStorage.getItem("@userdata"));
    return await api
      .get(`/apontamentos/${userdata.id_user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data.data?.reverse());
  }
};

export default loadApts;
