import styled from "styled-components";

export const StyledInput = styled.input`
    width: 100%;
    display: block;

    border: 2px solid var(--color2);
    border-radius: 5px;
    padding: 12px 12px;
    font-size: 18px;
`

export const StyledLabel= styled.label`
    width: 100%;
`