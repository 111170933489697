import { makeStyles } from "@material-ui/core/styles";
import { ErrorOutline } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  errorMessage: {
    textAlign: "center",
    fontSize: "1.8rem",
  },
  errorHeaders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
  },
  errorIcon: {
    width: "2rem",
    height: "2rem",
    color: theme.palette.error.main
  },
}));

const ErrorPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.errorHeaders}>
          <ErrorOutline className={classes.errorIcon} />
          <h1>Oops, algo deu errado!</h1>
        </div>
        <p className={classes.errorMessage}>
          Parece que houve um erro enquanto carregávamos essa página. Por favor,
          tente novamente mais tarde.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
