import styled from "styled-components";


export const ButtonCustom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 40px;
  width: 40px;
  font-size: 24px;
  color: var(--secondary);
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 8px;
  border: 1px solid #336ef033;
`;
