import {createBrowserRouter} from "react-router-dom";
import Login from "../pages/Login";
import Apontamento from "../pages/Apontamento";
import ControleHoras from "../pages/ControleHoras";
import Perfil from "../pages/Perfil";
import ErrorPage from "../pages/errorPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/apontamento",
    element: <Apontamento />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/controle_horas",
    element: <ControleHoras />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/perfil",
    element: <Perfil />,
    errorElement: <ErrorPage />,
  },
]);
