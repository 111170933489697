import Select from "react-select";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 8px;

  .Select__control {
    border: none; // Remove a borda do controle, se necessário
    box-shadow: none; // Remove a sombra do controle, se necessário
  }

  .Select__indicators {
    display: none; // Oculta o contêiner de indicadores
  }
  .Select__dropdown-indicator {
    display: none;
  }
`;

export const StyledLabel = styled.label`
  width: 100%;
`