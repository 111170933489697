import { styled } from "styled-components";

import { MdClose } from 'react-icons/md'

import theme from '../../../styles/theme'



export const StyledModalVincularTarefa = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    label {
      font-weight: 500;
    }

    input,
    label {
      
      width: 100%;
    }

    /* input {
      height: 25px;
      border: none;
      border-radius: 5px;
      padding-left: 5px;
    } */
  }

  h2,
  span {
    width: 100%;
    color: ${theme.colors.textSidebar};
  }

  .divBtns{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    .buttonRegister {
      background-color: var(--green-1);
      padding: 12px;
      border-radius: 8px;
      color: var(--white);
      margin-top: 8px;
      width: 35%;
      font-size: 16px;
    }

    .buttonCancel {
      border: none;
      background-color: ${theme.colors.backgroundLight};
      color: ${theme.colors.textSidebar};
      width: 35%;
      font-size: 16px;

    }
  }



  button {
    background-color: var(--green-1);
    padding: 12px;
    border-radius: 8px;
    color: var(--white);
    width: 100%;

    font-size: 1rem;
    font-weight: 700;

    margin-top: 10px;
  }
`;



export const ContainerContent = styled.div`
  height: 100%;
`

export const ContainerChildren = styled.div`
  height: '-10rem' ${props => (props.height ? props.height : '60rem')};
  overflow-y: auto;
`
export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.3em;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;

  .icon-close {
    cursor: pointer;
  }
`

export const ContainerClose = styled.div`
  cursor: pointer;
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const TextDescription = styled.span`
  color: ${theme.colors.textLight};
  margin-left: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
`

export const TextTitle = styled.h1`
  font-size: 24px;
  color: #8c8c8c;
  font-weight: 600;
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`

export const Header = styled.div`
  width: 100%;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  position: relative;
`

export const ContentDescriptionHeader = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`

export const BoxIconRotation = styled.div`
  background-color: ${props =>
    props.setBackgroundColor ? props.setBackgroundColor : theme.colors.info400};
  height: 36px;
  width: 36px;
  border-radius: 4px;

  transform: rotate(20deg);
  margin-right: 1rem;
`

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 8px;
  left: -10px;
  top: 8px;
  backdrop-filter: blur(8px);
  border: 2px solid #fff;
`
export const IconClose = styled(MdClose)`
  font-size: 24px;
  color: ${theme.colors.textSidebar};
`
export const TextHeader = styled.h3`
  font-weight: 600;
  color: #787878;  
  font-size: 16px;
`
