const theme = {
  colors: {
    backgroundLight: "#FAFAFA",
    backgroundLightShade: "#F6F6F6",
    backgroundLightShade50: "#F6F6F680",
    backgroundSidebar: "#EBEEEF",
    textBody: "#212427",
    textSidebar: "#8c8c8c",
    textSidebarShade: "#8C8C8C99",
    textLight: "#b1b2b5",
    blackStrong: "#000000",
    matte: "#28383B",
    bgConfirmation: "#2f80ed",
    bgSteps: "#f0f0f7",
    white: "#FFFFFF",
    primaryLight: "#D1E9AD",
    primary500: "#78AD35",
    primary700: "#557826",
    primary: "#8AC240",
    primaryDark: "#014e87",
    secundary200: '#336EF01A',
    secondaryLight: "#C1D7FC",
    info400: "#90C6E9",
    secondary: "#336EF0",
    secondaryDark: "#2239AB",
    border: "#dbdbdb",
    redLight: "#ff7b5a",
    info: "#4FA6DA",
    buttonGroup: "#86c5e5",
    btnGreen: "#21cf6e",
    btnGreenDark: "#28a745",
    btnGray: "#8C8C8CB2",
    btnGrayDark: "#8C8C8C",
    error: "#DF5959",
    error200: "#F9CFCF",
    error400: "#F17572",
    warning: "#FFB220",
    warning200: "#FFF0C6",
    success: "#59AE6D",
    transparent: "#00000000",
    textgray: "#e3e3e3",
    textgrayShade: "#757575",
    greenBackground: "#E7F4D3",
    greenBackgroundText: "#5C8A26",
    greenBackgroundButton: "#8AC240",
    inputBackground: "#f8f8fc",
    lineInWhite: "#e6e6f0",
    pink500: '#F72585'
  }
}


export default theme;
