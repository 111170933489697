import { Box, FormControl, Modal } from "@mui/material";
import { SiMicrosoftexcel } from "react-icons/si";
import {
  ContainerModal,
  DivTopModal,
  DivBottomModal,
  StyledSelectProject,
} from "./style";
import { useContext, useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { AptContext } from "../../../providers/AptContext/AptContext";
import * as S from "./style.js";
import theme from "../../../styles/theme.js";
import { messageError } from "../../../utils/message.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: theme.colors.backgroundLight,
  boxShadow: 24,
  // height: 400,
  display: "flex",
  gap: 4,
  flexDirection: "column",
  textAlign: "center",
  borderRadius: "5px",
  border: "none",
};

const ModalExportRda = ({ openModalExport, setOpenModalExport }) => {
  const [idProjeto, setIdProjeto] = useState("");
  const [projetoSelecionado, setProjetoSelecionado] = useState();
  const [projetosOptions, setProjetosOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { projetos, user, setTasks, tasks, setProjetos, setUser } =
    useContext(AptContext);

  const token = localStorage.getItem("@token");
  const userdata = JSON.parse(localStorage.getItem("@userdata"));

  const formSchema = yup.object().shape({
    inicio: yup.string().required("Tarefa Obrigatória"),
    fim: yup.date().required("Data obrigatória"),
    id_projeto: yup.string().required("Projeto obrigatório"),
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleDownload = (data) => {
    const inicioFormatted = new Date(data.inicio).toISOString().split("T")[0];
    const fimFormatted = new Date(data.fim).toISOString().split("T")[0];

    const ds = `${projetoSelecionado.label} - ${inicioFormatted} - ${fimFormatted}`;
    setIsLoading(true);

    api({
      url: `/apontamentos/${inicioFormatted}/${fimFormatted}/rda/${data.id_projeto}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob", // important
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${ds}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);

        URL.revokeObjectURL(href);

        setOpenModalExport(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          localStorage.clear();
          messageError("Token expirado");
          navigate("/");
        }

        try {
          messageError(JSON.parse(err.request.response).Erro);
        } catch (error) {
          messageError("Não foram encontrados apontamentos");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleProjeto = (project) => {
    setValue("id_projeto", project?.value);
    setProjetoSelecionado(project);
  };

  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  useEffect(() => {
    if (!token || !userdata) {
      // navigate("/");
    } else {
      if (userdata) {
        api
          .get(`/operador/${userdata.id_user}/tarefa`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((resp) => {
            setTasks(resp.data.data);
          })
          .catch((err) => {
            if (
              JSON.parse(err.request.response).Erro ==
              "Token expirado ou inválido"
            ) {
              // clearUser(navigate);
            } else {
              messageError(JSON.parse(err.request.response).Erro);
            }
          });
      }
    }
  }, []);

  useEffect(() => {
    const novosProjetos = [];
    const stringNovosProjetos = [];

    tasks.forEach((task) => {
      if (!stringNovosProjetos.includes(task.ds_projeto)) {
        stringNovosProjetos.push(task.ds_projeto);
        novosProjetos.push({
          ds_projeto: task.ds_projeto,
          id_projeto: task.id_projeto,
        });
      }
    });

    setProjetos(novosProjetos);
  }, [tasks]);

  useEffect(() => {
    api
      .get(`/operador/${userdata.id_user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setUser(resp.data.data);
      })
      .catch((err) => {
        if (
          JSON.parse(err.request.response).Erro == "Token expirado ou inválido"
        ) {
          // clearUser(navigate);
        } else {
          messageError(JSON.parse(err.request.response).Erro);
        }
      });

    const getProjetos = async () => {
      await api
        .get("/projeto", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          const {
            data: { projects },
          } = r;

          const projetosTratado = projects?.map((projetoTratar) => {
            return {
              value: projetoTratar.id,
              label: projetoTratar.ds_projeto,
            };
          });

          setProjetosOptions(projetosTratado);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getProjetos();
  }, []);

  return (
    <Modal
      open={openModalExport}
      onClose={() => setOpenModalExport(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className="boxModal" sx={style}>
        <ContainerModal>
          <S.Header>
            <S.ContentDescriptionHeader>
              <S.BoxIconRotation
                setBackgroundColor={theme.colors.primaryLight}
              ></S.BoxIconRotation>
              <S.BoxIcon>
                <SiMicrosoftexcel
                  color={theme.colors.primary500}
                  size={16}
                ></SiMicrosoftexcel>
              </S.BoxIcon>
              <S.TextHeader>Exportar RDA</S.TextHeader>
            </S.ContentDescriptionHeader>
            <S.ContainerClose onClick={() => setOpenModalExport(false)}>
              <S.IconClose />
            </S.ContainerClose>
          </S.Header>
          <DivBottomModal>
            <form
              className="styledForm"
              onSubmit={handleSubmit(handleDownload)}
            >
              <FormControl className="formControl">
                <div className="divSelectUser">
                  <div className="selectUser">
                    <StyledSelectProject
                      {...register("id_projeto")}
                      placeholder="Selecione um Projeto"
                      value={projetoSelecionado}
                      onChange={handleProjeto}
                      options={projetosOptions}
                      isClearable
                    />
                  </div>
                </div>
                <div className="divDate">
                  <label>Inicio</label>
                  <input
                    id="inicio"
                    name="inicio"
                    type="date"
                    {...register("inicio")}
                  />
                </div>
                <div className="divDate">
                  <label>Fim</label>
                  <input id="fim" name="fim" type="date" {...register("fim")} />
                </div>
                <div className="divBtn">
                  {isLoading ? (
                    <ClipLoader
                      css={override}
                      size={30}
                      color={"#000000"}
                      loading={isLoading}
                    />
                  ) : (
                    <button className="btnConfirm" type="submit">
                      Download
                    </button>
                  )}
                </div>
              </FormControl>
            </form>
          </DivBottomModal>
        </ContainerModal>
      </Box>
    </Modal>
  );
};

export default ModalExportRda;
