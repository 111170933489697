import styled from "styled-components";
import Select from "react-select";
import theme from '../../styles/theme'

export const StyledControleHoras = styled.div`
  background-color: ${theme.colors.backgroundLight};
  width: 100%;
  margin-bottom: 100px;

  .divAdm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 32px;
    h1{
      width: 100%;
      font-size: 24px;
    }
  }

  .divDataBloqueio {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 22px;
    /* border: 2px solid black; */
    padding: 5px;
    border-radius: 5px;
    column-gap: 8px;

    .textDataBloqueio{
      font-size: 20px;
      font-weight: 400;
      color: ${theme.colors.textSidebar};
    }

    .textDataBloqueioBold{
      font-size: 20px;
      font-weight: 500;
      color: ${theme.colors.textSidebar};
    }

    .btnEditBloqueio{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colors.white};
      color: ${theme.colors.warning};
      box-shadow: 0px 0px 4px 0px #5E5D5D1A;
      width: 40px;
      height: 40px;

      :hover{
        background-color: ${theme.colors.warning}10;
      }
    }

    input {
      font-size: 22px;
    }

    label {
      font-weight: 600;
      width: auto;
    }

    /* button {
      background-color: green;
      padding: 6px;
      border-radius: 8px;
      width: 100px;
      color: var(--white);
      font-weight: 500;
      font-size: 1.1rem;
    } */
  }

  .divSelectUser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    flex-direction: row;
    width: 500px;
  }

  .selectUser {
    display: flex;
    align-items: center;
  }

  .buttonExport {
    height: 54px;
    cursor: pointer;
    border-radius: 6px;
    color: green;
    font-size: 30px;
  }

  .container {
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.backgroundLight};

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .divBtn {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      width: 100%;
      height: 70px;

      button {
        background-color: var(--color5);
        padding: 12px;
        border-radius: 8px;
        color: var(--white);
      }
    }
    .btnFiltrar {
      background-color: var(--green-1);
      padding: 12px;
      border-radius: 8px;
      color: var(--white);
      margin-top: 8px;
    }

    .containerList {
      width: 100%;
      height: 60vh;

      margin-top: 30px;
    }
    .dataHora {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      column-gap: 16px;
      margin-top: 32px;

      .mes {
        display: flex;
        justify-content: space-around;
        align-items: center;

        width: 100%;

        select {
          width: 100px;
          text-align: center;
          padding: 5px;
        }

        label {
          font-weight: 600;
        }
      }
    }
  }

  .buttonRDA {
    width: 500px;
    text-align: center;

    button {
      background-color: green;
      padding: 12px;
      border-radius: 8px;
      color: var(--white);
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 24px;
    }
  }
`;

export const StyledSelectUser = styled(Select)`
  width: 450px;
`;

export const DivBtnExport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  column-gap: 16px;
  margin: 32px 0 16px 0;

  button{
    padding: 8px 16px;
    color: ${theme.colors.textSidebar};
    background-color: ${theme.colors.white};
    box-shadow: 0px 0px 4px 0px #5E5D5D1A;
    border-radius: 4px;
    size: 16px;
    font-weight: 700;
  }
  .buttonExportDados{

  }

  .informationBtnExport{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    .iconBtnExport{
      color: ${theme.colors.textSidebar};
    }
  }
`

export const DivTotalHoras = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 4rem;
  font-size: 1.5rem;
`

export const BoxIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 18px;
`

export const ButtonIcon = styled.button`
  background-color: transparent;
  cursor: pointer;
`

export const DivAcoesTable = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  width: 100%;
`