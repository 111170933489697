import { StyledFormProfile, StyledPerfil } from "./style";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import Header from "../../components/Header";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AptContext } from "../../providers/AptContext/AptContext";
import { clearUser } from "../../utils/clearUser";
import Layout from "../_layout";
import { messageError, messageSuccess } from "../../utils/message";

const Perfil = () => {
  const token = localStorage.getItem("@token");
  // const userdata = JSON.parse(localStorage.getItem("@userdata"));
  const { user } = useContext(AptContext);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const formSchema = yup.object().shape({
    jira_token_api: yup.string(),
    tx_whats: yup
      .string()
      .length(11, "O número deve ter exatamente 11 caracteres")
      .matches(/^\d+$/, "Deve conter apenas dígitos"),
    password: yup.string(),
    // .min(6, "minimum 6 caracters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Senhas não conferem"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    setValue("jira_token_api", user?.jira_token_api);
    setValue("tx_whats", user?.tx_whats);
  }, [user, setValue]);

  const submitForm = (data) => {
    for (let chave in data) {
      if (data[chave] === "") {
        delete data[chave];
      }
    }
    delete data["confirmPassword"];

    api
      .patch(`/operador/${user.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        messageSuccess("Salvo");
        navigate("/apontamento");
      })
      .catch((err) => {
        if (
          JSON.parse(err.request.response).Erro == "Token expirado ou inválido"
        ) {
          clearUser(navigate);
        } else {
          messageError(JSON.parse(err.request.response).Erro);
        }
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <StyledPerfil>
        <h1>Editar Perfil</h1>

        <StyledFormProfile onSubmit={handleSubmit(submitForm)}>
          <div className="divLabel">
            <label>Email</label>
            <input defaultValue={user.email} disabled type="text" />
          </div>
          <div className="divLabel">
            <label>Nome</label>
            <input defaultValue={user.nm_operador} disabled type="text" />
          </div>
          <div className="divLabel">
            <label>Jira Token</label>
            {errors.jira_token_api ? (
              <span>{" - " + errors.jira_token_api?.message}</span>
            ) : undefined}
          </div>
          <input
            placeholder="Insira seu Jira token"
            type="password"
            {...register("jira_token_api")}
          />
          <div className="divLabel">
            <label>WhatsApp</label>
            {errors.tx_whats ? (
              <span>{" - " + errors.tx_whats?.message}</span>
            ) : undefined}
          </div>
          <input
            placeholder="DDD + Número"
            type="text"
            {...register("tx_whats")}
          />
          <div className="divLabel">
            <label>Senha</label>
            {errors.password ? (
              <span>{" - " + errors.password?.message}</span>
            ) : undefined}
          </div>
          <input
            placeholder="Digite sua nova senha"
            type={showPassword ? "text" : "password"}
            {...register("password")}
          />
          <div className="divLabel">
            <label>Confirme a senha</label>
            {errors.confirmPassword ? (
              <span>{" - " + errors.confirmPassword?.message}</span>
            ) : undefined}
            <div className="divPassword">
              <input
                placeholder="Confirme sua nova senha"
                type={showPassword ? "text" : "password"}
                {...register("confirmPassword")}
              />
              {showPassword ? (
                <VisibilityIcon onClick={handleShowPassword} />
              ) : (
                <VisibilityOffIcon onClick={handleShowPassword} />
              )}
            </div>
          </div>
          <button className="btnTask">Salvar</button>
        </StyledFormProfile>
      </StyledPerfil>
    </Layout>
  );
};

export default Perfil;
