export const filterAptsMes = (apt, mes, ano) => {
  return apt.filter((apt) => {
    const data = new Date(apt.dt_apontamento);
    return (
      data.getMonth() === parseInt(mes, 10) &&
      data.getFullYear() === parseInt(ano, 10)
    );
  });
};

export const filterAptsDay = (aptMes, day) => {
  return aptMes.filter((apt) => {
    const data = new Date(apt.dt_apontamento);
    return data.getDate() == day;
  });
};
