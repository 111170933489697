import { StyledLabel, StyledTextArea } from "./styled"

export const StyledTextAreaComponent = ({register, ...rest}) => {
    if (Boolean(register)) {
        return <StyledTextArea
            {...register}
            {...rest}
        />
    }

    return <StyledTextArea
        {...rest}
    />
}

export const LabeledStyledTextArea = ({label, ...rest}) => {
    return <StyledLabel htmlFor={rest.id}>
        {label}
        <StyledTextAreaComponent {...rest} />
    </StyledLabel>
}