import {styled} from "styled-components";

export const StyledAptTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-height: 100%;

  th {
    background-color: #a9a9a9;
  }

  td {
    background-color: var(--color2);
  }

  th,
  td {
    border-radius: 5px;
    text-align: center;
    padding: 4px;
  }
  td {
    height: 20px;
  }

  .emptyCel{
    border-radius: 0;
  }
`;
