import { removeAcento } from "./strings";
import { filtrosRetrabalho } from "../constants/filterRetrabalho";
import { messageError } from "./message";

export const retrabalhoLogic = (data, setLoading) => {
  const fraseTratada = removeAcento(data["ds_apontamento"].toLowerCase());

  const isInvalid = filtrosRetrabalho.some((filtro) => {
    const treatedFiltro = removeAcento(filtro);

    if (fraseTratada.includes(treatedFiltro)) {
      messageError(`Não usar a palavra "${filtro}" se não for retrabalho.`);

      setLoading && setLoading(false);

      return true;
    }

    return false;
  });

  return isInvalid;
};

export const validacaoPBI = (data) => {
  if (data["ao_solicita_pbi"] === "S") {
    if (!data["pbi_key"]) {
      messageError("PBI é obrigatória");
      return false;
    } else if (data["pbi_key"].length > 20) {
      messageError("PBI pode ter no máximo 20 caracteres.");
      return false;
    }
  }

  return true;
};
