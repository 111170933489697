import styled, { keyframes } from "styled-components";

import theme from "../../styles/theme";


export const Container = styled.aside`

  position: fixed;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  transition: 500ms all;
  height: 100%;
  z-index: 1;
  border-right: 1px solid ${theme.colors.textgray};
  transition: all 0.4s ease;
  user-select: none;
  @media (min-width: 700px) {
    grid-area: sidenav;
  }

  @media (max-width: 1023px) {
    position: absolute;
    height: ${(props) => (!props.expanded ? "3em" : "100vh")};
    border: ${(props) =>
    !props.expanded ? "none" : `1px solid ${theme.colors.textgray}`};
    top: 0;
    z-index: 4;
    width: ${(props) => (!props.expanded ? "3em" : "100vw")};
  }

  &.menu-open #container {
    background-color: ${theme.colors.error};
  }
`;

export const MenuIcon = styled.div`
  background-color: ${(props) =>
    props.expanded ? theme.colors.backgroundSidebar : "none"};
  transition: all 0.4s ease;
  padding: 26px 17px;

  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 1024px) {
    display: ${(props) => (props.expanded ? "none" : "")};
  }
`;

export const IconMenu = styled.div`
  transition: all 0.4s ease;
`;

export const ImgIcon = styled.img`
  height: 20px;
  width: 24px;
  transition: all 0.4s ease;
`;

export const Box = styled.div`
  height: 100%;
  background-color: red;
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  width: ${(props) => (props.expanded ? "27rem" : "auto")};
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.4s ease;
  display: ${(props) => (props.expanded ? "block" : "none")};

  /*   ::-webkit-scrollbar{
    display: none;
  } */

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.backgroundSidebar};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.lineInWhite};
    border-radius: 11px;
  }

  @media (min-width: 1024px) {
    display: block;
  }

  @media (max-width: 1024px) {
    width: 100vw;
    height: 100vh;
    display: block;
    left: ${(props) =>
    props.expanded ? (props.collapsed ? "0" : "-110vw") : "-110vw"};
  }
`;

export const LogoMenu = styled.div`
  width: ${(props) => (props.expanded ? "12rem" : "6rem")};
  margin-top: 1.5rem;
  margin-left: ${(props) => (props.expanded ? "1rem" : "0rem")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;

  img {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    margin-top: 3rem;
    margin-left: 1.4rem;
    padding-bottom: 3.5rem;
  }
`;

export const ImgLogoC = styled.img`
  /* width: 4.5rem; */
  width: 90%;
  height: 90%;
  transform: scale(1.1)
`;
export const ImgLogoOV = styled.img`
  width: 8rem;
  padding-top: 0.5rem;
  display: ${(props) => (props.expanded ? "flex" : "none")};
`;

export const StatusMenuButton = styled.div`
  display: flex;
  position: absolute;
  width: 2.25rem;
  z-index: 2;
  height: 2.25rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${theme.colors.white};
  color: ${theme.colors.secondaryDark};
  left: ${(props) => (props.expanded ? "26.3rem" : "5.3rem")};
  top: 5.5rem;
  transition: all 0.4s ease;
  box-shadow: 3px 2px 10px 0px rgba(140, 140, 140, 0.15);
  .fa-angle-left {
    height: 1.2em;
    display: ${(props) => (props.expanded ? "flex" : "none")};
  }

  .fa-angle-right {
    height: 1.2em;
    display: ${(props) => (props.expanded ? "none" : "flex")};
  }

  @media (max-width: 1024px) {
    right: 0em;
    left: ${(props) =>
    props.expanded ? (props.collapsed ? "auto" : "94vw") : "-100vw"};
    top: 2em;
    position: fixed;
    background-color: ${theme.colors.backgroundSidebar};
    width: 2em;
    height: 2em;
  }
`;

export const StatusSubMenuButton = styled.div`
  opacity: ${(props) => (props.collapsed ? 0 : 1)};
  display: flex;
  position: absolute;
  width: 2.25rem;
  z-index: 2;
  height: 2.25rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props) => (props.collapsed ? "none" : "pointer")};
  background-color: ${theme.colors.white};
  color: ${theme.colors.textSidebar};
  left: ${(props) =>
    props.collapsed ? "-27rem" : props.expanded ? "53rem" : "32rem"};
  top: 5.5rem;
  box-shadow: 3px 2px 10px 0px rgba(140, 140, 140, 0.15);
  transition: all 0.4s ease;
  .fa-angle-left {
    height: 1.2em;
  }

  .fa-angle-right {
    height: 1.2em;
    display: ${(props) => (props.expanded ? "none" : "flex")};
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LinksSideBar = styled.div`
  //overflow-y: scroll;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
`;

export const SubMenuTitle = styled.h3`
  display: flex;
  font-size: 1.6rem;
  color: ${theme.colors.textSidebar};
  margin-bottom: 5.5rem;
  margin-left: 3.4rem;
  margin-top: 1.5rem;

  svg {
    margin-right: 1rem;
  }
  @media (max-width: 1000px) {
    margin-top: 4.8rem;
    font-size: 2.6rem;
  }
`;

export const ItemList = styled.ul`
  width: 0px;
  list-style: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.4s ease;
`;
export const ItemHeader = styled.div`
  cursor: pointer;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.textSidebar};
  display: flex;
  width: ${(props) => (props.expanded ? "26rem" : "6rem")};
  height: 5rem;
  justify-content: center;

  margin-right: ${(props) => (props.expanded ? "2rem" : "0")};

  &:hover:after {
    content: "${(props) => props.alt}";
    position: absolute;
    margin-top: 2.5rem;
    left: 3.4rem;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.4rem;
    padding: 0.5rem 0.9rem;
    font-size: 1.4rem;
    transition: 0s;
    display: ${(props) => (props.expanded ? "none" : "display")};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    width: max-content;
    pointer-events: none;
    z-index: 4;
  }
  @media (max-width: 1000px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const ItemHeaderExit = styled.div`
  cursor: pointer;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  width: ${(props) => (props.expanded ? "26rem" : "6rem")};
  height: 5rem;
  justify-content: center;
  margin-right: ${(props) => (props.expanded ? "2rem" : "0")};

  &:hover:after {
    content: "${(props) => props.alt}";
    position: absolute;
    margin-top: 40rem;
    left: 4rem;
    background-color: ${({ theme }) => theme.colors.secundary200};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.4rem;
    padding: 0.5rem 0.9rem;
    font-size: 1.4rem;
    transition: 0s;
    display: ${(props) => (props.expanded ? "none" : "display")};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    width: max-content;
    pointer-events: none;
    z-index: 4;
  }
  @media (max-width: 1000px) {
    width: 37rem;
  }
`;

export const TitleHolder = styled.div`
  cursor: pointer;
  color: ${(props) =>
    !props.active ? theme.colors.textSidebar : theme.colors.secondary};
  display: ${(props) => (props.expanded ? "flex" : "none")};
  height: 6rem;
  font-size: 1.2em;
  justify-content: space-between;
  align-items: center;
  width: 22rem;
  position: relative;
  .seta {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0;
  }
  &:hover {
    color: ${(props) =>
    props.active ? theme.colors.textSidebarShade : theme.colors.secondary};
    font-weight: 700;
  }
  @media (max-width: 1000px) {
    width: 28rem;
  }
`;

export const ItemTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => (!props.active ? "none " : theme.colors.secondary)};
  background-color: ${(props) =>
    !props.active ? "none " : theme.colors.secundary200};
  font-size: 1rem;
  width: ${(props) => (props.expanded ? "24rem" : "4rem")};
  border-radius: 0.5rem;
  height: 4rem;
  padding: 1rem;
  padding-top: 1rem;

  &:hover {
    color: ${(props) =>
    props.active ? theme.colors.textSidebarShade : theme.colors.secondary};
    background-color: ${theme.colors.secundary200};
    font-weight: 700;
  }

  svg {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1rem;
    margin-right: ${(props) => (props.expanded ? "1rem" : "0")};
  }

  @media (max-width: 1000px) {
    width: ${(props) => (props.expanded ? "35rem" : "4rem")};
    font-size: 1.5rem;
  }
`;

export const BoxIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconTitle = styled.div`
  width: 1rem;
  height: 1rem;
  color-adjust: ${({ theme }) => theme.colors.primary};
`;

export const collapse = keyframes`
  0% {
    height: unset;
    opacity: 1;
  }
  25% {
    height: unset;
    opacity: 0.75;
  }
  50% {
    height: 20rem;
    opacity: 0.5;
  }
  75% {
    height: 10rem;
    opacity: 0.25;
  }
  100% {
    height: 0rem;
    opacity: 0;
    visibility: collapse;
  }
`;
export const rise = keyframes`
  0% {
    height: 0rem;
    opacity: 0;
  }
  25% {
    height: 10rem;
    opacity: 0.25;
  }
  50% {
    height: 20rem;
    opacity: 0.5;
  }
  75% {
    height: unset;
    opacity: 0.75;
  }
  100% {
    height: unset;
    opacity: 1;
  }
`;

export const leftanimation = keyframes`
0% {
  left: -100px;
}
100% {
  left: 0;
}
`;

export const BoxItems = styled.div`
  width: 27rem;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1;
  left: ${(props) =>
    props.collapsed ? "-27rem" : props.expanded ? "27rem" : "6rem"};
  background-color: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.textgray};
  border-right: 1px solid ${theme.colors.textgray};
  overflow-y: auto;
  transition: all 0.4s ease;
  flex-direction: column;
  opacity: ${(props) => (props.collapsed ? 0 : 1)};

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.backgroundSidebar};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.lineInWhite};
    border-radius: 11px;
  }

  @media (max-width: 1023px) {
    width: 100vw;
    left: ${(props) => (props.collapsed ? "-100vw" : "0")};
  }
`;
export const BackButtonMobile = styled.div`
  transition: all 0.4s ease;
  color: ${theme.colors.primaryLight};
  margin-top: 3em;
  margin-left: 2.2em;
  font-size: 0.9em;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
`;
export const CadastroBasico = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const CadastroBasicoTitle = styled.div`
  color: ${({ theme }) => theme.colors.textSidebar};
  padding-left: 3.5rem;
  margin-top: 2.4rem;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
  display: ${(props) => (props.empty ? "block" : "none")};
  @media (max-width: 1000px) {
    font-size: 2rem;
  }
`;

export const Separador = styled.div`
  color: ${({ theme }) => theme.colors.textSidebarShade};
  border-bottom: 1px solid ${({ theme }) => theme.colors.textSidebarShade};
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: ${(props) => (props.empty ? "block" : "none")};
`;

export const ItemBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0.9rem;
  cursor: pointer;
  transition: all 0.4s ease;
  padding-inline-start: 1.6rem;
  font-weight: ${(props) => (props.selected ? 700 : "")};
`;

export const ItemIcon = styled.div`
  font-size: 2rem;
`;

export const GearText = styled.div`
  font-size: 1.4rem;
  color: ${theme.colors.textSidebar};
  padding: 2px 8px 2px 8px;
  width: 42px;
  &:hover {
    color: ${theme.colors.primary};
    background-color: ${theme.colors.backgroundLightShade};
    font-weight: 700;
  }

  height: 25px;
  font-size: 1.5rem;

  align-items: center;
  justify-content: center;
  display: ${(props) => (props.expanded ? "flex" : "none")};
  @media (max-width: 1000px) {
    font-size: 1.75rem;
  }
`;

export const GearHolder = styled.div`
  font-size: 1.3em;
  width: ${(props) => (props.expanded ? "23rem" : "3rem")};
  border-radius: 0.5rem;
  display: flex;
  justify-content: ${(props) => (props.expanded ? "flex-start" : "center")};
  height: 2em;
  &:hover {
    color: ${theme.colors.secondary};
    background-color: ${theme.colors.secundary200};
    font-weight: 700;
  }
  &:hover > div {
    color: ${theme.colors.secondary};
  }
  &:hover > svg {
    color: ${theme.colors.secondary};
  }
  &::before {
    color: red;
    height: 2rem;
    content: " ";
    width: 2rem;
    position: absolute;
  }
  font-weight: 500;
  color: ${(props) =>
    !props.active ? theme.colors.textSidebar : theme.colors.secondary};
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  bottom: 1em;
  left: 1em;
  cursor: pointer;
  transition: all 0.4s ease;
`;

export const ItemIconStatus = styled.div``;

export const ItemStatus = styled.div`
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  border: none;
  background-color: white;
  display: inline-block;
`;

export const ItemText = styled.div`
  margin-left: 1rem;
  transition: opacity 0.6s ease;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: ${(props) => (props.active ? 700 : 400)};
  color: ${(props) =>
    props.active ? theme.colors.white : theme.colors.textSidebar};
  background-color: ${(props) =>
    props.active ? theme.colors.primary : "none"};
  height: 3rem;
  width: 22rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  &:hover {
    color: ${(props) =>
    props.active ? theme.colors.white : theme.colors.secondary};
    background-color: ${(props) =>
    props.active ? theme.colors.primary : theme.colors.secundary200};
    font-weight: 700;
  }
  @media (max-width: 1000px) {
    width: 33rem;
    font-size: 1.8rem;
  }
`;

export const ItemNumber = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

export const ItemInboxNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #1e75ff;
  padding: 0.5rem 0.9rem;
  border-radius: 0.4rem;
  color: #ffffff;
  opacity: 1;
  transition: all 0.4s ease;
`;

export const ItemTooltip = styled.div`
  position: absolute;
  margin-top: 2.5rem;
  left: 3.4rem;

  background-color: white;
  color: var(--background-color);
  border-radius: 0.4rem;
  padding: 0.5rem 0.9rem;
  font-size: 1.4rem;
  transition: 0s;
  display: flex;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  width: max-content;
  pointer-events: none;
`;
export const SideBarFooter = styled.div`
  //position: fixed;
  bottom: 0;
  padding: 0.5rem;
`;
export const Link = styled.a`
  text-decoration: none;

  &:visited {
    text-decoration: none;
    color: #8c8c8c;
  }
`;
