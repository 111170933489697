import { Box, FormControl, Modal } from "@mui/material";
import { StyledSelect, StyledDivChangeTask, StyledTr } from "./style";
import { useContext, useEffect, useState } from "react";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { ModalEditAptContext } from "../../../../providers/ModalEditApt/ModalEditApt";
import { AptContext } from "../../../../providers/AptContext/AptContext";
import loadApts from "../../../../utils/loadApts";
import { clearUser } from "../../../../utils/clearUser";
import { filterAptsDay, filterAptsMes } from "../../../../utils/filterApts";
import { css } from "@emotion/react";
import * as S from "./style.js";
import theme from "../../../../styles/theme.js";
import { FaEdit } from "react-icons/fa";
import { messageError, messageSuccess } from "../../../../utils/message.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: 500,
  display: "flex",
  gap: 4,
  flexDirection: "column",
  textAlign: "center",
  borderRadius: "5px",
  border: "none",
};

const ModalChangeTask = ({ selectedApt }) => {
  const { openModalChangeTask, setOpenModalChangeTask } =
    useContext(ModalEditAptContext);
  const closeModal = () => {
    setOpenModalChangeTask(false);
  };
  const token = localStorage.getItem("@token");
  const userdata = JSON.parse(localStorage.getItem("@userdata"));
  const navigate = useNavigate();
  const {
    user,
    setApt,
    tasks,
    setTasks,
    projetos,
    setProjetos,
    mes,
    ano,
    apontamentosMes,
    setApontamentosMes,
  } = useContext(AptContext);
  const { setFilteredApt } = useContext(ModalEditAptContext);
  const [projetoSelecionado, setProjetoSelecionado] = useState("");
  const [selectedTarefa, setSelectedTarefa] = useState(null);
  const date = selectedApt.dt_apontamento?.split("T")[0].slice(8);
  const [setIsLoading] = useState(false);

  const formSchema = yup.object().shape({
    id_tarefa: yup.string().required("Tarefa Obrigatória"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (userdata) {
      api
        .get(`/operador/${userdata.id_user}/tarefa`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          setTasks(resp.data.data);
        })
        .catch((err) => {
          if (
            JSON.parse(err.request.response).Erro ==
            "Token expirado ou inválido"
          ) {
            clearUser(navigate);
          } else {
            messageError(JSON.parse(err.request.response).Erro);
          }
        });
    }
  }, []);

  useEffect(() => {
    const novosProjetos = [];

    tasks.forEach((task) => {
      if (!novosProjetos.includes(task.ds_projeto)) {
        novosProjetos.push(task.ds_projeto);
      }
    });

    setProjetos(novosProjetos);
  }, [tasks]);

  const projetosOptions = projetos.map((projeto) => ({
    value: projeto.id_projeto,
    label: projeto.ds_projeto,
  }));

  const formattedOptions = tasks
    .filter((task) => {
      return task.id_projeto === projetoSelecionado?.value;
    })
    .map((tarefa) => ({
      label: `${tarefa.key} ${tarefa.ds_tarefa}`,
      value: tarefa.id,
    }));

  const onChangeTarefa = (selectedOption) => {
    setSelectedTarefa(selectedOption);
    setValue("id_tarefa", selectedOption?.value);
  };

  const handleProjeto = (project) => {
    onChangeTarefa(null);
    setProjetoSelecionado(project);
  };
  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  const submitForm = (data) => {
    console.log("submit", data);
    if (user.jira_token_api != null) {
      // setIsLoading(true);
      api
        .patch(`/apontamentos/${selectedApt.id}/atualizatarefa`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (resp) => {
          const loadAptsData = async () => {
            const apts = await loadApts(token);
            setApt(apts);
            const filteredApts = filterAptsMes(apts, mes, ano);
            setApontamentosMes(filteredApts);
          };
          await loadAptsData();
          const updatedApt = apontamentosMes.map((apt) =>
            apt.id == selectedApt.id ? resp.data.data : apt
          );
          const filterApt = filterAptsDay(updatedApt, date);
          setFilteredApt(filterApt);
          messageSuccess("Apontamento editado");
          closeModal();
        })
        .catch((err) => {
          if (
            JSON.parse(err.request.response).Erro ==
            "Token expirado ou inválido"
          ) {
            clearUser(navigate);
          } else {
            messageError(JSON.parse(err.request.response).Erro);
          }
        })
        .finally(() => {
          // setIsLoading(false);
        });
    } else {
      messageError("Adicione seu Jira Token");
    }
  };
  return (
    <Modal
      open={openModalChangeTask}
      onClose={() => setOpenModalChangeTask(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className="boxModal" sx={style}>
        <S.Header>
          <S.ContentDescriptionHeader>
            <S.BoxIconRotation
              setBackgroundColor={theme.colors.warning + "5A"}
            ></S.BoxIconRotation>
            <S.BoxIcon>
              <FaEdit color={theme.colors.warning} size={16} />
            </S.BoxIcon>
            <S.TextHeader>{`Editar Tarefas (${selectedApt.task_key})`}</S.TextHeader>
          </S.ContentDescriptionHeader>
          <S.ContainerClose onClick={closeModal}>
            <S.IconClose />
          </S.ContainerClose>
        </S.Header>
        <StyledDivChangeTask>
          {/* <div className="divBtnCancelar">
            <IoIosClose className="btnCancelar" onClick={closeModal} />
          </div> */}
          {selectedApt ? (
            <StyledTr>
              <tbody>
                <tr>
                  <th>Jira</th>
                  <th>Descrição</th>
                  <th>Hora Inicio</th>
                  <th>Hora Fim</th>
                  <th>Horas</th>
                </tr>
                <td>{selectedApt.task_key}</td>
                <td className="dsApontamento">
                  {selectedApt.ds_apontamento?.length > 30
                    ? selectedApt.ds_apontamento?.slice(0, 30) + "..."
                    : selectedApt.ds_apontamento}
                </td>
                <td>{selectedApt.hr_inicio}</td>
                <td>{selectedApt.hr_fim}</td>
                <td>{parseFloat(selectedApt.total_horas).toFixed(2)}</td>
              </tbody>
            </StyledTr>
          ) : undefined}
          <h2>Altere o projeto e a tarefa conforme necessário.</h2>
          <form className="styledForm" onSubmit={handleSubmit(submitForm)}>
            <FormControl className="formControl">
              <StyledSelect
                placeholder="Selecione um Projeto"
                value={projetoSelecionado}
                onChange={handleProjeto}
                options={projetosOptions}
                isClearable
              />
              {projetoSelecionado ? (
                <StyledSelect
                  {...register("id_tarefa")}
                  value={selectedTarefa}
                  onChange={onChangeTarefa}
                  options={formattedOptions}
                  isSearchable
                  isClearable
                  placeholder="Selecione a tarefa"
                />
              ) : null}
            </FormControl>
            <div className="divBtns">
              <button onClick={closeModal} className="buttonCancel">
                Cancelar
              </button>
              <button type="submit" className="buttonRegister">
                Salvar
              </button>
            </div>
          </form>
        </StyledDivChangeTask>
      </Box>
    </Modal>
  );
};

export default ModalChangeTask;
