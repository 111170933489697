import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledForm, StyledLogin } from "./style";
import api from "../../services/api";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ModalRecoveryPassword from "../../components/Modals/ModalRecoveryPassword";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LabeledStyledInputComponent } from "../../components/StyledInput";
import LogoMenuPhrase from "../../assets/svg/logoJSMX.svg";
import { messageError } from "../../utils/message";

const Login = () => {
  const navigate = useNavigate();
  const [openModalRecPass, setOpenModalRecPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const token = localStorage.getItem("@token");

  useEffect(() => {
    if (token) {
      try {
        const user = jwt_decode(token);
        if (user) {
          navigate("/apontamento");
        }
      } catch (error) {}
    }
  }, []);

  const formSchema = yup.object().shape({
    username: yup
      .string()
      .email("Digite um email válido")
      .required("Email Obrigatório"),
    password: yup.string().required("Senha obrigatória"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const submitForm = async (data) => {
    const recaptchaToken = await executeRecaptcha();
    data.recaptchaToken = recaptchaToken;
    api
      .post("/auth", data)
      .then((resp) => {
        const getToken = resp.data.data;
        localStorage.setItem("@token", getToken);
        const user = jwt_decode(getToken);
        localStorage.setItem("@userdata", JSON.stringify(user.user));
      })
      .then((_) => navigate("/apontamento"))
      .catch((err) => {
        if (err.response?.status == 403) {
          messageError("Email ou senha inválidos!");
        } else if (err.response?.status == 401) {
          localStorage.clear();
          messageError("Token expirado");
          navigate("/");
        } else {
          messageError("Falha na comunicação com o servidor");
        }
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ModalRecoveryPassword
        openModal={openModalRecPass}
        setOpenModal={setOpenModalRecPass}
      />
      <StyledLogin>
        <div className="box">
          <img
            className="imgLogo"
            alt="Logomarca da JSMX"
            src={LogoMenuPhrase}
          />
          <StyledForm onSubmit={handleSubmit(submitForm)}>
            <h1>Login</h1>
            <LabeledStyledInputComponent
              label="Email"
              id="username"
              name="username"
              register={register("username")}
              className="style-input"
              placeholder="mail@mail...."
            />

            <div className="divPassword">
              <LabeledStyledInputComponent
                label="Senha"
                id="password"
                name="password"
                register={register("password")}
                className="style-input"
                type={showPassword ? "text" : "password"}
                placeholder="Digite sua senha"
              />

              <>
                {showPassword ? (
                  <VisibilityIcon onClick={handleShowPassword} />
                ) : (
                  <VisibilityOffIcon onClick={handleShowPassword} />
                )}
              </>
            </div>
            <div className="divBtn">
              <p onClick={() => setOpenModalRecPass(true)}>Recuperar senha</p>
              <button className="buttonRegister" type="submit">
                Entrar
              </button>
            </div>
          </StyledForm>
        </div>
      </StyledLogin>
    </>
  );
};

export default Login;
