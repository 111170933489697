import {styled} from "styled-components";

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  padding: 0 15px 0 30px;
  width: 900px;
  margin-top: 40px;
  margin-bottom: 40px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  div {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .logoutBtn {
    background-color: var(--color5);
    padding: 12px;
    border-radius: 8px;
    height: 45px;
    font-size: 1rem;
  }

  @media (width < 1300px) {
    flex-direction: row;
    width: 90%;
    height: 80px;
    h1 {
      width: 30%;
      margin: auto;
    }
    div {
      margin-top: 0;
    }
  }
  @media (width < 900px) {
    flex-direction: column;
    width: 90%;
    height: 80px;
    h1 {
      width: 500px;
      margin-bottom: 10px;
    }
    div {
      margin-top: 0;
    }
  }
`;
