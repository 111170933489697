import {useState} from "react";
import {createContext} from "react";

export const ModalEditAptContext = createContext({});

export const ModalEditAptProviders = ({children}) => {
  const [openModalEditApt, setOpenModalEditApt] = useState(false);
  const [openModalChangeTask, setOpenModalChangeTask] = useState(false)
  const [filteredApt, setFilteredApt] = useState([]);
  const [selectedApt, setSelectedApt] = useState({});
  const [openChildModal, setOpenChildModal] = useState(false);
  const [openModalDelApt, setOpenModalDelApt] = useState(false);
  const [idApt, setIdApt] = useState('');



  return (
    <ModalEditAptContext.Provider
      value={{
        openModalEditApt,
        setOpenModalEditApt,
        filteredApt,
        setFilteredApt,
        openModalChangeTask,
        setOpenModalChangeTask,
        selectedApt, 
        setSelectedApt,
        openChildModal, 
        setOpenChildModal,
        openModalDelApt, 
        setOpenModalDelApt,
        idApt, 
        setIdApt
      }}
    >
      {children}
    </ModalEditAptContext.Provider>
  );
};
