import styled from "styled-components";
import theme from "../../styles/theme"
export const StyledLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.backgroundLight};
  color: ${theme.colors.textSidebar};

  width: 100vw;
  height: 100vh;

  h1 {
    padding: 15px;
    color: ${theme.colors.textSidebar};
  }

  .box {
    background-color: ${theme.colors.backgroundLight};
    border-radius: 10px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    
    
    .imgLogo {
      width: 400px;
      border-radius: 5px;
      box-shadow: 0px 0px 4px 0px ${theme.colors.secondaryLight};
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 400px;
  gap: 12px;

  padding: 0 20px;

  /* label + span {
    color: red;
    font-size: 0.8rem;
  }

  input,
  label {
    font-size: 1.2rem;
  }

  input {
    width: 100%;
    height: 36px;
    border: 2px solid black;
    padding-left: 5px;

    border-radius: 5px;
  } */
  .divBtn {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;

    width: 100%;
    .buttonRegister {
      background-color: ${theme.colors.secondary};
      padding: 12px;
      border-radius: 8px;
      color: var(--white);
      margin-top: 8px;
      width: 100%;
    }

    p {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .divInput {
    width: 100%;
    margin-bottom: 3px;
    label {
      width: 100%;
      text-align: left;
    }
  }
  .divPassword {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    /* border: 2px solid black; */

    border-radius: 5px;

  }
`;
