import { StyledInput, StyledLabel } from "./styled";

export const StyledInputComponent = ({ register, ...rest }) => {
  if (Boolean(register)) {
    return <StyledInput {...register} {...rest} />;
  }

  return <StyledInput {...rest} />;
};

export const LabeledStyledInputComponent = ({ label, ...rest }) => {
  return (
    <StyledLabel>
      {label}
      <StyledInputComponent {...rest} />
    </StyledLabel>
  );
};
