import styled from "styled-components";
import { MdClose } from 'react-icons/md'
import theme from '../../../styles/theme'

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 12px;

  .mes {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;

    margin-bottom: 30px;

    select {
      width: 100px;
      text-align: center;
      padding: 5px;
    }

    label {
      font-weight: 600;
    }
  }

  .btnConfirm {
    background-color: var(--green-1);
    padding: 12px;
    border-radius: 8px;
    color: var(--white);
    margin-top: 8px;
    width: 100%;
    font-size: 16px;
}
`;

export const DivTopModal = styled.div``;
export const DivBottomModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  span {
    margin-bottom: 40px;
  }

  div {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .button {
    background-color: var(--green-1);
    padding: 12px;
    border-radius: 8px;
    color: var(--white);
    margin-top: 8px;
    width: 35%;
    font-size: 16px;
  }

  .notButton {
    background-color: #c20006;
    font-size: 16px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .mes {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .divDate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 60px;
    padding: 8px;

    border: 1px solid var(--color2);
    border-radius: 5px;
    padding-left: 10px;
    color: ${theme.colors.textSidebar};

    input {
      border: none;
      font-size: 14px;
      color: ${theme.colors.textSidebar};
    }
  }
  .styledForm {
    width: 100%;
    height: 100%;
  }
  .formControl {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px;
  }
  .divBtn {
    margin-top: 20px;
  }
`;


//stilização header modal
export const ContainerClose = styled.div`
  cursor: pointer;
`
export const Header = styled.div`
  width: 100%;
  padding: 12px 12px;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 1px 16px 0px rgba(227, 227, 227, 0.50);
`

export const ContentDescriptionHeader = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`

export const BoxIconRotation = styled.div`
  background-color: ${props =>
    props.setBackgroundColor ? props.setBackgroundColor : theme.colors.info400};
  height: 36px;
  width: 36px;
  border-radius: 4px;

  transform: rotate(20deg);
  margin-right: 1rem;
`

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 8px;
  left: 22px;
  top: 16px;
  backdrop-filter: blur(8px);
  border: 2px solid #fff;
`
export const IconClose = styled(MdClose)`
  font-size: 24px;
  color: ${theme.colors.textSidebar};
`
export const TextHeader = styled.h3`
  font-weight: 600;
  color: #787878;
  font-size: 16px;
`
