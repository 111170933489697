
export const LinksSidebar = [
  {
    icon: "FaClock",
    color: "red",
    title: "Apontamento de Horas",
    submenu: [
      {
        title: "Apontamento",
        // InboxNumber: '99',
        tooltip: "inbox",
        path: "/apontamento",
        submenutype: "outros",
      },
      {
        title: "Controle de Horas",
        // InboxNumber: '99',
        tooltip: "inbox",
        path: "/controle_horas",
        submenutype: "outros",
      },

    ],
  },
  {
    icon: "FaClipboard",
    color: "red",
    title: "Cadastros",
    submenu: [
      {
        title: "Perfil",
        // InboxNumber: '99',
        tooltip: "inbox",
        path: "/perfil",
        submenutype: "outros",
      },
    ],
  },
];
