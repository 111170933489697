import { StyledSelect, StyledLabel } from "./styles";

export const StyledSelectComponent = ({ register, ...rest }) => {
  if (Boolean(register)) {
    return <StyledSelect {...register} {...rest} />;
  }

  return <StyledSelect {...rest} />;
};

export const LabeledStyledSelect = ({ label, ...rest }) => {
  return (
    <StyledLabel htmlFor={rest.id}>
      {label}
      <StyledSelectComponent {...rest} />
    </StyledLabel>
  );
};
