import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../services/api.js";
import { useForm } from "react-hook-form";
import * as React from "react";

import { StyledModalVincularTarefa } from "./style";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../../utils/clearUser.js";
import * as S from "./style.js";
import { FaSlidersH } from "react-icons/fa";
import theme from "../../../styles/theme.js";
import { LabeledStyledInputComponent } from "../../StyledInput/index.jsx";
import { messageError, messageSuccess } from "../../../utils/message.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 350,
  bgcolor: theme.colors.backgroundLight,
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const ModalVincularTarefa = ({
  openModalVincularTarefa,
  setOpenModalVincularTarefa,
}) => {
  const handleClose = () => setOpenModalVincularTarefa(false);

  const token = localStorage.getItem("@token");
  const userdata = JSON.parse(localStorage.getItem("@userdata"));

  const formSchema = yup.object().shape({
    key: yup.string().required(),
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const submitForm = (data) => {
    api
      .post(`/operador/${userdata.id_user}/operador_tarefa`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((_) => {
        messageSuccess("Tarefa atribuida!");
        handleClose();
      })
      .catch((err) => {
        const error = JSON.parse(err.request.response);

        messageError(error.error);

        if (
          JSON.parse(err.request.response).Erro === "Token expirado ou inválido"
        ) {
          clearUser(navigate);
        }
      });
  };

  return (
    <div>
      <Modal
        open={openModalVincularTarefa}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <StyledModalVincularTarefa>
            <S.Header>
              <S.ContentDescriptionHeader>
                <S.BoxIconRotation
                  setBackgroundColor={theme.colors.warning}
                ></S.BoxIconRotation>
                <S.BoxIcon>
                  <FaSlidersH color={theme.colors.warning} size={16} />
                </S.BoxIcon>
                <S.TextHeader>Vincular Tarefa</S.TextHeader>
              </S.ContentDescriptionHeader>
              <S.ContainerClose onClick={handleClose}>
                <S.IconClose />
              </S.ContainerClose>
            </S.Header>
            <form onSubmit={handleSubmit(submitForm)}>
              <span>
                Vinculação de tarefas é exclusiva para os projetos em que você
                participa.
              </span>
              <LabeledStyledInputComponent
                label={"Código da tarefa"}
                type="text"
                placeholder="Ex: CA-90"
                {...register("key")}
                value={watch("key")}
                onChange={(e) => setValue("key", e.target.value)}
              />
              <div className="divBtns">
                <button onClick={handleClose} className="buttonCancel">
                  Cancelar
                </button>
                <button type="submit" className="buttonRegister">
                  Vincular
                </button>
              </div>
            </form>
          </StyledModalVincularTarefa>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalVincularTarefa;
