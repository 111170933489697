import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        font-family: "Roboto", sans-serif;
    }
    :root{
      font-size: 62.5%; // 62.5% = 10px
    }
    
    *::-webkit-scrollbar {
        display: none;
    }
    
    body {
        width: 100%;
        height: 100%;
        background-color: var(--backgroundLight);
        padding-bottom: 2rem;
    }
    
    button {
      cursor: pointer;
      border: none;
      font-weight: 500;
      font-size: 16px;
      color: var(--white);
      
    }

    button:hover{
      opacity: 1.3;
      transition: background-color 0.2s;

      transition: all 0.1s ease-in-out;
      transform: scale(1.02);
    }

    button:disabled {
      opacity: 0.5;
    }

    button:focus {
      outline: none;
    }


    a {
        color: unset;
        text-decoration: none;
    }

    ul, ol, li {
        list-style: none;
    }
    
    label {
      font-size: 14px;
    }


    :root {
        --color1: #e3e3e3;
        --color2: #d3d3d3;
        --color3: #a9a9a9;
        --color4: #3e3e3e;
        --color5: #000030;

        --green-1: #8AC240;
        --green-2: #4CB944;
        
        --white: #FFFFFF;
        --black: #000000;

        --grey100: #333333;
        --grey50: #8C8C8C;
        --grey20: #E0E0E0;
        --grey0: #F5F5F5;

        --color-negative: #E60000;
        --color-warning: #FFCD07;
        --color-success: #168821;
        --color-information: #155BCB;

        --toastify-color-success: #168821;
        --toastify-color-error: #E60000;
        --toastify-font-family: "Roboto", sans-serif;

        --backgroundLight: #FAFAFA;
        --backgroundLightShade: #F6F6F6;
        --backgroundLightShade50: #F6F6F680;
        --backgroundSidebar: #EBEEEF;
        --textBody: #212427;
        --textSidebar: #8c8c8c;
        --textSidebarShade: #8C8C8C99;
        --textLight: #b1b2b5;
        --blackStrong: #000000;
        --matte: #28383B;
        --bgConfirmation: #2f80ed;
        --bgSteps: #f0f0f7;
        --white: #FFFFFF;
        --primaryLight: #D1E9AD;
        --primary500: #78AD35;
        --primary700: #557826;
        --primary: #8AC240;
        --primaryDark: #014e87;
        --secondaryLight: #C1D7FC;
        --info400: #90C6E9;
        --secondary: #336EF0;
        --secondaryDark: #2239AB;
        --border: #dbdbdb;
        --redLight: #ff7b5a;
        --info: #4FA6DA;
        --buttonGroup: #86c5e5;
        --btnGreen: #21cf6e;
        --btnGreenDark: #28a745;
        --btnGray: #8C8C8CB2;
        --btnGrayDark: #8C8C8C;
        --error: #DF5959;
        --error200: #F9CFCF;
        --error400: #F17572;
        --warning: #FFB220;
        --success: #59AE6D;
        --transparent: #00000000;
        --textgray: #e3e3e3;
        --textgrayShade: #757575;
        --greenBackground: #E7F4D3;
        --greenBackgroundText: #5C8A26;
        --greenBackgroundButton: #8AC240;
        --inputBackground: #f8f8fc;
        --lineInWhite: #e6e6f0;
    }

    .toast-font-size {
      font-size: 16px;
    }
`;

export const StyledContainer = styled.div`
  width: 1500px;
  margin: 0 auto;

  @media (width < 1600px) {
    width: 1200px;
  }
  @media (width < 1300px) {
    width: 900px;
  }
  @media (width < 1000px) {
    width: 600px;
  }
  @media (width < 700px) {
    width: 420px;
  }
  @media (width < 500px) {
    width: 90%;
  }
`;
