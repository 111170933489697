import styled from "styled-components";

export const StyledTextArea = styled.textarea`
    width: 100%;
    font-size: 17px;
`;

export const StyledLabel = styled.label`
  width: 100%;

  textarea {
      width: 100%;
      height: 100px;

      padding: 10px;
      border-radius: 5px;

      resize: none;
    }
`