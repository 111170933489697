import React from "react";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import theme from "../../styles/theme";

export const SwitchButton = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme: themes }) => ({
  width: 55,
  height: 31,
  padding: 0,
  "@media (max-width: 768px)": {
    width: 40,
    height: 25,
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(23px)",
      "@media (max-width: 768px)": {
        transform: "translateX(15px)",
      },
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          themes.palette.mode === "dark"
            ? theme.colors.secondaryDark
            : theme.colors.secondary,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#2239AB4D",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        themes.palette.mode === "light"
          ? themes.palette.grey[100]
          : themes.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: themes.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 27,
    height: 27,
    "@media (max-width: 768px)": {
      width: 21,
      height: 21,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: themes.palette.mode === "light" ? "#EBEEEF" : "#2239AB4D",
    opacity: 1,
    transition: themes.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
