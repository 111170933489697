import React from "react";
import ReactDOM from "react-dom/client";
import Providers from "./providers/Providers";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import theme from "./styles/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/globalStyle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const root = ReactDOM.createRoot(document.getElementById("root"));
const key = process.env.REACT_APP_KEY_SITE_RECAPTCHA
root.render(
  <GoogleReCaptchaProvider
  reCaptchaKey={key}
  scriptProps={{
    async: false,
    defer: false,
    appendTo: 'head',
    nonce: undefined
  }}
  container={{
    element: 'idkeyrecaptcha',
    parameters: {
      badge: 'bottomright'
    }
  }}
>
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div id="idkeyrecaptcha" />
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
      <Providers>
        <RouterProvider router={router} />
      </Providers>
    </ThemeProvider>
  </React.StrictMode>
  </GoogleReCaptchaProvider>
);
