import {
  FirstContainer,
  StyledApontamento,
  TableDivAcoes,
  TextIsRetrabalho,
} from "./style";
import * as yup from "yup";
import api from "../../services/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AptContext } from "../../providers/AptContext/AptContext";
import loadApts from "../../utils/loadApts";
import { FormControl } from "@mui/material";
import ModalVincularTarefa from "../../components/Modals/ModalVincularTarefa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { clearUser } from "../../utils/clearUser";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { retrabalhoLogic, validacaoPBI } from "../../utils/apotamentos";
import { LabeledStyledSelect } from "../../components/StyledSelect";
import { LabeledStyledTextArea } from "../../components/StyledTextArea";
import { LabeledStyledInputComponent } from "../../components/StyledInput";
import Layout from "../_layout";
import { SwitchButton } from "../../components/SwitchButton";
import theme from "../../styles/theme";
import { TableMaterial } from "../../components/TableMaterial";
import compararDatas from "../../utils/comparaDatas";
import { messageError, messageSuccess } from "../../utils/message";
import { ModalEditAptContext } from "../../providers/ModalEditApt/ModalEditApt";
import { ButtonIcon } from "../ControleHoras/style";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import ModalEditApt from "../../components/Modals/ModalEditApt";
import { GiCardExchange } from "react-icons/gi";

const Apontamento = () => {
  const { apt, setApt, user, setUser, tasks, projetos, setTasks, setProjetos } =
    useContext(AptContext);

  const {
    setOpenModalChangeTask,
    setSelectedApt,
    setOpenChildModal,
    setIdApt,
    setOpenModalDelApt,
  } = useContext(ModalEditAptContext);

  const navigate = useNavigate();
  const [ultimosApts, setUltimosApts] = useState([]);
  const [openModalVincularTarefa, setOpenModalVincularTarefa] = useState(false);
  const [projetoSelecionado, setProjetoSelecionado] = useState();
  const [selectedTarefa, setSelectedTarefa] = useState(null);
  const [selectedOptionData, setSelectedTarefaData] = useState(null);

  const token = localStorage.getItem("@token");
  const userdata = JSON.parse(localStorage.getItem("@userdata"));
  const currentDate = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: "Data",
      field: "dt_apontamento",
      width: 20,
      render: (rowData) => {
        const data = new Date(rowData.dt_apontamento);
        const dia = data.getDate();
        const mes = data.getMonth() + 1;
        const ano = data.getFullYear();
        const dataFormatada = `${dia.toString().padStart(2, "0")}/${mes
          .toString()
          .padStart(2, "0")}/${ano.toString().slice(-2)}`;
        return (
          <TextIsRetrabalho
            is_ausente={rowData.is_ausente}
            isRetrabalho={rowData.is_retrabalho}
          >
            {dataFormatada}
          </TextIsRetrabalho>
        );
      },
    },
    {
      title: "Task",
      field: "task_key",
      width: 30,
      render: (rowData) => (
        <TextIsRetrabalho
          is_ausente={rowData.is_ausente}
          isRetrabalho={rowData.is_retrabalho}
        >
          {rowData.task_key || "-"}
        </TextIsRetrabalho>
      ),
    },
    {
      title: "Nº PBI",
      field: "pbi_key ? rowData.pbi_key : '-'",
      width: 30,
      render: (rowData) => (
        <TextIsRetrabalho
          is_ausente={rowData.is_ausente}
          isRetrabalho={rowData.is_retrabalho}
        >
          {rowData.pbi_key || "-"}
        </TextIsRetrabalho>
      ),
    },
    {
      title: "Descrição",
      field: "ds_apontamento",
      render: (rowData) => (
        <TextIsRetrabalho
          is_ausente={rowData.is_ausente}
          isRetrabalho={rowData.is_retrabalho}
          style={{ width: "260px" }}
        >
          {rowData.ds_apontamento.length > 35
            ? rowData.ds_apontamento.slice(0, 35) + "..."
            : rowData.ds_apontamento}
        </TextIsRetrabalho>
      ),
    },
    {
      title: "Inicio",
      field: "hr_inicio",
      width: 20,
      render: (rowData) => (
        <TextIsRetrabalho
          is_ausente={rowData.is_ausente}
          isRetrabalho={rowData.is_retrabalho}
        >
          {rowData.hr_inicio || "-"}
        </TextIsRetrabalho>
      ),
    },
    {
      title: "Fim",
      field: "hr_fim",
      width: 20,
      render: (rowData) => (
        <TextIsRetrabalho
          is_ausente={rowData.is_ausente}
          isRetrabalho={rowData.is_retrabalho}
        >
          {rowData.hr_fim || "-"}
        </TextIsRetrabalho>
      ),
    },
    {
      title: "Horas Totais",
      field: "total_horas",
      width: 20,
      render: (rowData) => (
        <TextIsRetrabalho
          is_ausente={rowData.is_ausente}
          isRetrabalho={rowData.is_retrabalho}
        >
          {Number(rowData.total_horas).toFixed(2)}
        </TextIsRetrabalho>
      ),
    },
    {
      title: "Ações",
      width: 20,
      render: (rowData) => {
        return (
          <TableDivAcoes is_ausente={rowData.is_ausente}>
            {!rowData.is_ausente && (
              <>
                <ButtonIcon
                  onClick={() => {
                    setSelectedApt(rowData);
                    setOpenModalChangeTask(true);
                  }}
                >
                  <GiCardExchange color={theme.colors.warning} size={18} />
                </ButtonIcon>
                <ButtonIcon
                  onClick={() => {
                    setSelectedApt(rowData);
                    setOpenChildModal(true);
                  }}
                >
                  <FaEdit color={theme.colors.secondary} size={18} />
                </ButtonIcon>
              </>
            )}
            <ButtonIcon
              onClick={() => {
                setIdApt(rowData.id);
                setOpenModalDelApt(true);
              }}
            >
              <FaTrashAlt color={theme.colors.error} size={18} />
            </ButtonIcon>
          </TableDivAcoes>
        );
      },
    },
  ];

  const formSchema = yup.object().shape({
    id_tarefa: yup.string().when("is_ausente", {
      is: false,
      then: (schema) => schema.required("Tarefa Obrigatória"),
      otherwise: (schema) => schema.notRequired(),
    }),
    dt_apontamento: yup.date().required("Data obrigatória"),
    hr_inicio: yup.string().when("is_ausente", {
      is: false,
      then: (schema) => schema.required("Hora inicial obrigatória"),
      otherwise: (schema) => schema.notRequired(),
    }),
    hr_fim: yup.string().when("is_ausente", {
      is: false,
      then: (schema) => schema.required("Hora final obrigatória"),
      otherwise: (schema) => schema.notRequired(),
    }),
    ds_apontamento: yup
      .string()
      .when("is_ausente", {
        is: false,
        then: (schema) => schema.required("Descrição obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      })
      .required("Descrição obrigatória"),
    type: yup.string().when("is_ausente", {
      is: false,
      then: (schema) => schema.required("Projeto não selecionado"),
      otherwise: (schema) => schema.notRequired(),
    }),
    is_retrabalho: yup.boolean().default(false),
    is_bug: yup.boolean().default(false),
    is_ausente: yup.boolean().default(false),
    ds_justificativa_retrabalho: yup
      .string()
      .when("is_retrabalho", {
        is: true,
        then: (schema) => schema.required("Justificativa obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      })
      .when("is_bug", {
        is: true,
        then: (schema) => schema.required("Justificativa obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      }),
    ao_solicita_pbi: yup.string().default("N"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);

    // return;
    api
      .post("/apontamentos", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((_) => {
        messageSuccess("Apontamento feito com sucesso");
        reset();
        setValue("is_ausente", false);
        setSelectedTarefa(null);
        setProjetoSelecionado(null);
      })
      .then(async (_) => {
        const apts = await loadApts(token);
        setApt(apts);
      })
      .catch((err) => {
        if (
          JSON.parse(err.request.response).Erro == "Token expirado ou inválido"
        ) {
          clearUser(navigate);
        } else {
          messageError(JSON.parse(err.request.response).Erro);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitForm = (data) => {
    if (data.is_ausente) {
      setIsLoading(true);
      const { is_ausente, dt_apontamento, ...rest } = data;

      onSubmit({
        is_ausente,
        dt_apontamento,
        ds_apontamento: "Ausente",
        tp_lancamento: "12345",
        id_operador_inclusao: user.id,
      });
    } else {
      if (data["hr_fim"] <= data["hr_inicio"]) {
        messageError("Hora final menor ou igual à Hora Inicial");
      } else if (user.jira_token_api != null) {
        setIsLoading(true);
        data["tp_lancamento"] = "12345";
        data["id_operador_inclusao"] = user.id;

        if (!data["is_retrabalho"]) {
          const isInvalid = retrabalhoLogic(data, setIsLoading);

          if (isInvalid) {
            return;
          }
        }

        if (
          !data["type"].toLowerCase().includes("bug") &&
          !data["is_retrabalho"]
        ) {
          data["ds_justificativa_retrabalho"] = undefined;
        }

        const isPBIValido = validacaoPBI(data);

        if (!isPBIValido) {
          setIsLoading(false);
          return;
        }

        const { is_bug, ...treatedData } = data;

        onSubmit(treatedData);
      } else {
        messageError("Adicione seu Jira Token");
      }
    }
  };

  const projetosOptions = projetos.map((projeto) => {
    return {
      value: projeto.id_projeto,
      label: projeto.ds_projeto,
    };
  });

  const formattedOptions = tasks
    .filter((task) => {
      return task.id_projeto === projetoSelecionado?.value;
    })
    .map((tarefa) => ({
      label: `${tarefa.key} ${tarefa.ds_tarefa}`,
      value: tarefa.id,
    }));

  const onChangeTarefa = (selectedOption) => {
    const selectedOptionData = tasks.find((task) => {
      return selectedOption?.value === task.id;
    });

    setSelectedTarefa(selectedOption);
    setSelectedTarefaData(selectedOptionData);
    setValue("id_tarefa", selectedOption?.value);
  };

  const handleProjeto = (project) => {
    onChangeTarefa(null);

    try {
      api
        .get(`/projeto/${project.value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setValue("ao_solicita_pbi", res.data.projeto.ao_solicita_pbi);
        });
    } catch (error) {
      messageError("Erro ao carregar solicita PBI");
    }
    setProjetoSelecionado(project);
  };

  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  useEffect(() => {
    if (!token || !userdata) {
      navigate("/");
    } else {
      const loadAptsData = async () => {
        const apts = await loadApts(token);
        setApt(apts);
        setUltimosApts(apts);
      };
      loadAptsData();
      const userdata = JSON.parse(localStorage.getItem("@userdata"));
      if (userdata) {
        api
          .get(`/operador/${userdata.id_user}/tarefa`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((resp) => {
            setTasks(resp.data.data);
          })
          .catch((err) => {
            if (
              JSON.parse(err.request.response).error ==
              "Token expirado ou inválido"
            ) {
              clearUser(navigate);
            } else {
              messageError(JSON.parse(err.request.response).error);
            }
          });
      }
    }
  }, []);

  useEffect(() => {
    const novosProjetos = [];
    const stringNovosProjetos = [];

    tasks.forEach((task) => {
      if (!stringNovosProjetos.includes(task.ds_projeto)) {
        stringNovosProjetos.push(task.ds_projeto);
        novosProjetos.push({
          ds_projeto: task.ds_projeto,
          id_projeto: task.id_projeto,
        });
      }
    });

    setProjetos(novosProjetos);
  }, [tasks]);

  useEffect(() => {
    setUltimosApts(apt);
  }, [apt]);

  useEffect(() => {
    api
      .get(`/operador/${userdata.id_user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setUser(resp.data.data);
      })
      .catch((err) => {
        if (
          JSON.parse(err.request.response).Erro == "Token expirado ou inválido"
        ) {
          clearUser(navigate);
        } else {
          messageError(JSON.parse(err.request.response).Erro);
        }
      });
  }, []);

  useEffect(() => {
    setValue("is_retrabalho", false);
    setValue("is_bug", false);

    if (selectedOptionData) {
      let is_retrabalho_value = selectedOptionData?.id_tarefa_pai;
      let is_bug_value;

      if (selectedOptionData?.type?.toLowerCase().includes("bug")) {
        is_retrabalho_value = true;
        is_bug_value = true;
      } else {
        is_retrabalho_value = false;
        is_bug_value = false;
      }

      if (is_retrabalho_value) {
        setValue("is_retrabalho", is_retrabalho_value);
        setValue("is_bug", is_bug_value);
      }

      setValue("type", selectedOptionData.type);
    }
  }, [selectedOptionData]);

  useEffect(() => {
    const errorsArr = Object.entries(errors);

    if (Boolean(errorsArr[0])) {
      messageError(errorsArr[0][1].message);
    }
  }, [errors]);

  return (
    <Layout>
      <ModalEditApt />
      <ModalVincularTarefa
        openModalVincularTarefa={openModalVincularTarefa}
        setOpenModalVincularTarefa={setOpenModalVincularTarefa}
      />
      <StyledApontamento>
        {/* <Header /> */}
        <div className="header-btn">
          <h1>Apontamento de Horas</h1>
          <button
            className="btnTask"
            onClick={() => setOpenModalVincularTarefa(true)}
          >
            Vincular Tarefa
          </button>
        </div>

        <div className="container">
          <div className="divForm">
            <form className="styledForm" onSubmit={handleSubmit(submitForm)}>
              <FormControl className="formControl">
                <FirstContainer>
                  <LabeledStyledInputComponent
                    label="Data"
                    id="dt_apontamento"
                    name="dt_apontamento"
                    type="date"
                    defaultValue={currentDate}
                    register={register("dt_apontamento")}
                  />
                  <div className="container-switch">
                    <SwitchButton
                      value={"is_ausente"}
                      name={"is_ausente"}
                      {...register("is_ausente")}
                      onChange={(e, c) => {
                        setValue("is_ausente", c);
                      }}
                      checked={watch("is_ausente")}
                    />
                    <label style={{ whiteSpace: "nowrap" }}>Ausente</label>
                  </div>
                </FirstContainer>
                <LabeledStyledSelect
                  label={"Projeto"}
                  id="selectProjeto"
                  placeholder="Selecionar Projeto"
                  value={projetoSelecionado}
                  onChange={handleProjeto}
                  options={projetosOptions}
                  isClearable
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "6px 0px",
                    }),
                  }}
                  isDisabled={watch("is_ausente")}
                />
                <LabeledStyledSelect
                  label={"Tarefa"}
                  id="selectTarefa"
                  register={register("id_tarefa")}
                  value={selectedTarefa}
                  onChange={onChangeTarefa}
                  options={formattedOptions}
                  isSearchable
                  isClearable
                  isDisabled={
                    !Boolean(projetoSelecionado) || watch("is_ausente")
                  }
                  placeholder="Selecionar Tarefa"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "6px 0px",
                    }),
                  }}
                />

                <div className="divBothHours">
                  <LabeledStyledInputComponent
                    label="Horário de Início"
                    id="hr_inicio"
                    name="hr_inicio"
                    type="time"
                    register={register("hr_inicio")}
                    className="style-input"
                    placeholder="00:00"
                    disabled={watch("is_ausente")}
                  />
                  <LabeledStyledInputComponent
                    label="Horário de Término"
                    id="hr_fim"
                    name="hr_fim"
                    type="time"
                    register={register("hr_fim")}
                    className="style-input"
                    placeholder="00:00"
                    disabled={watch("is_ausente")}
                  />
                </div>
                <LabeledStyledTextArea
                  id="ds_apontamento"
                  name="ds_apontamento"
                  label="Descrição"
                  placeholder="Escreva a descrição aqui..."
                  type="text"
                  register={register("ds_apontamento")}
                  disabled={watch("is_ausente")}
                />
                <div className="divBtn">
                  <div className="divSwitchLabel">
                    <SwitchButton
                      value={"is_retrabalho"}
                      name={"is_retrabalho"}
                      {...register("is_retrabalho")}
                      onChange={(e, c) => {
                        setValue("is_retrabalho", c);
                      }}
                      checked={watch("is_retrabalho")}
                      disabled={watch("is_ausente")}
                    />
                    <label style={{ color: theme.colors.pink500 }}>
                      Retrabalho
                    </label>
                  </div>

                  <div className="divSwitchLabel">
                    <SwitchButton
                      value={"ao_solicita_pbi_checkbox"}
                      name={"ao_solicita_pbi_checkbox"}
                      {...register("ao_solicita_pbi_checkbox")}
                      onChange={(e, c) => {
                        if (c) {
                          setValue("ao_solicita_pbi", "S");
                        } else {
                          setValue("ao_solicita_pbi", "N");
                          setValue("pbi_key", null);
                        }
                      }}
                      checked={watch("ao_solicita_pbi") === "S"}
                      disabled={watch("is_ausente")}
                    />
                    <label>Nº PBI</label>
                  </div>
                </div>
                {(watch("is_bug") || watch("is_retrabalho")) && (
                  <LabeledStyledTextArea
                    label="Justificativa de retrabalho"
                    id="ds_justificativa_retrabalho"
                    name="ds_justificativa_retrabalho"
                    placeholder="Justificativa retrabalho"
                    type="text"
                    register={register("ds_justificativa_retrabalho")}
                  />
                )}
                {watch("ao_solicita_pbi") === "S" && (
                  <LabeledStyledInputComponent
                    label="N° PBI"
                    id="pbi_key"
                    register={register("pbi_key")}
                    placeholder="N° PBI"
                    type="text"
                  />
                )}
                {isLoading ? (
                  <ClipLoader
                    css={override}
                    size={30}
                    color={"#000000"}
                    loading={isLoading}
                  />
                ) : (
                  <button type="submit" className="buttonRegister">
                    Registrar Horas
                  </button>
                )}
              </FormControl>
            </form>
          </div>
          <div className="listaApt">
            {apt.length > 0 ? (
              // <ListaApt apt={ultimosApts}></ListaApt>
              <TableMaterial
                // isLoading={}
                label="Últimos Apontamentos"
                search={false}
                pagination={false}
                components={{
                  Toolbar: () => null,
                }}
                data={ultimosApts.slice(0, 12).sort(compararDatas)}
                columns={columns}
                style={{ width: "100%" }}
              />
            ) : (
              <div>
                {" "}
                <h2>Você não possui últimos apontamentos</h2>
              </div>
            )}
          </div>
        </div>
      </StyledApontamento>
    </Layout>
  );
};

export default Apontamento;
