import { toast } from "react-toastify";

export const messageError = (message) => {
  return toast.error(message, {
    bodyClassName: "toast-font-size",
  });
};

export const messageSuccess = (message) => {
  return toast.success(message, {
    bodyClassName: "toast-font-size",
  });
};
