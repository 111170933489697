import AptTableRow from "../AptTableRow";
import {StyledAptTable} from "./style";

const AptTable = ({apontamentos, setSelectedApt, setOpenChildModal}) => {
  const rows = apontamentos.map((apt) => (
    <AptTableRow
      key={apt.id}
      apt={apt}
      setSelectedApt={setSelectedApt}
      setOpenChildModal={setOpenChildModal}
    />
  ));

  const total_horas = apontamentos.reduce((acc, obj) => {
    return acc + parseFloat(obj.total_horas);
  }, 0);

  return (
    <StyledAptTable>
      <tbody>
        <tr>
          <th>Jira</th>
          <th>Descrição</th>
          <th>Hora Inicio</th>
          <th>Hora Fim</th>
          <th>Horas</th>
        </tr>
        {rows}
        <tr>
          <th>Horas totais</th>
          <th className="emptyCel"></th>
          <th className="emptyCel"></th>
          <th className="emptyCel"></th>
          <th>{total_horas.toFixed(2)}</th>
        </tr>
      </tbody>
    </StyledAptTable>
  );
};

export default AptTable;
