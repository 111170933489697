import {styled} from "styled-components";

export const StyledModalRecPass = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

  h2 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;

    span {
      color: red;
      font-size: 0.8rem;
      padding-left: 5px;
    }

    label {
      margin-bottom: 5px;
    }
    input {
      padding: 5px;
      font-size: 1rem;
      border-radius: 5px;
    }

    div {
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    background-color: var(--color5);
    padding: 12px;
    border-radius: 8px;
    width: 150px;
    color: var(--white);

    font-size: 1rem;
    font-weight: 700;
  }
`;
