import {useEffect, useState} from "react";
import {createContext} from "react";
import mesAtual from "../../utils/mesAtual";
import api from "../../services/api";

export const AptContext = createContext({});

export const AptProviders = ({children}) => {
  const [apt, setApt] = useState([]);
  const [apontamentosMes, setApontamentosMes] = useState([]);
  const [mes, setMes] = useState(() => mesAtual() - 1);
  const [ano, setAno] = useState(2024);
  const [user, setUser] = useState({});
  const [tasks, setTasks] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [routesAllowed] = useState([])
  

  const token = localStorage.getItem("@token");
  const userdata = JSON.parse(localStorage.getItem("@userdata"));

  useEffect(() => {
    if (userdata) {
      api
        .get(`/operador/${userdata.id_user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          setUser(resp.data.data);
        });
    }
  }, []);

  return (
    <AptContext.Provider
      value={{
        apt,
        setApt,
        apontamentosMes,
        setApontamentosMes,
        mes,
        setMes,
        ano,
        setAno,
        user,
        setUser,
        routesAllowed,
        tasks,
        setTasks,
        projetos,
        setProjetos,
      }}
    >
      {children}
    </AptContext.Provider>
  );
};
