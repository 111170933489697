import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../services/api.js";
import { useForm } from "react-hook-form";
import * as React from "react";

import { StyledModalRecPass } from "./style";
import { messageError, messageSuccess } from "../../../utils/message.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 230,
  bgcolor: "var(--white)",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function ModalRecoveryPassword({ openModal, setOpenModal }) {
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const formSchema = yup.object().shape({
    email: yup.string().email("Digite um email válido").required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const submitForm = (data) => {
    api
      .post("/password_reset", data)
      .then((_) => {
        messageSuccess("Email enviado!");
        handleClose();
      })
      .catch((_) => {
        messageError("Email não encontrado na base de dados");
      });
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <StyledModalRecPass>
            <h2>Recuperar senha</h2>
            <form onSubmit={handleSubmit(submitForm)}>
              <label>Email</label>
              <input
                type="email"
                placeholder="Digite seu email"
                {...register("email")}
              />
              {errors.email && <span>{errors.email.message}</span>}
              <div>
                <button onClick={handleClose}>Cancelar</button>
                <button type="submit">Enviar</button>
              </div>
            </form>
          </StyledModalRecPass>
        </Box>
      </Modal>
    </div>
  );
}
