import { styled } from "styled-components";
import theme from "../../styles/theme"

export const StyledPerfil = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1{
    color: ${theme.colors.textSidebar};
  }
`;

export const StyledFormProfile = styled.form`
  width: 400px;
  height: 520px;

  border-radius: 10px;

  padding: 10px 20px;
  margin-top: 40px;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  color: ${theme.colors.textSidebar};

  label + span {
    color: red;
    font-size: 0.8rem;
    font-weight: 500;
  }

  input{
    border: 2px solid ${theme.colors.textLight};
  }
  label {
    font-size: 1.2rem;
    outline: none;
  }

  input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding: 5px;
  }

  .divLabel {
    width: 100%;
    margin-top: 10px;
    label {
      width: 100%;
      text-align: left;
      font-weight: 500;
    }
  }
  .btnTask {
      background-color: ${theme.colors.secondary};
      padding: 12px 24px;
      border-radius: 8px;
      color: var(--white);
      width: 100%;
      margin-top: 15px;
    }


  .divPassword {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;

    border: 2px solid ${theme.colors.textLight};

    border-radius: 5px;

    padding-right: 10px;

    input {
      border: none;
      height: 32px;
    }
  }
`;
