import React from 'react'

import IconPrint from '@mui/icons-material/Print'
import {
  TablePagination,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from '@mui/material'

import MaterialTable, {
  MTableGroupRow,
  MTableHeader,
  MTableToolbar,
} from 'material-table'

import styled from 'styled-components'

import theme from '../../styles/theme'



export const TableMaterial = ({
  columns,
  isGroup,
  toolbar,
  GroupRow,
  pagination = true,
  tableHeader,
  titleAction,
  titleShow,
  title,
  onPrint,
  search = true,
  ...rest
}) => {
  // const defaultMaterialTheme = createTheme()
  const defaultMaterialTheme = createTheme({
    palette: {
      primary: {
        main: theme.colors.secondary
      },
      secondary: {
        main: theme.colors.primary
      }
    }
  })

  const style = {
    backgroundColor: '#ffffff',
    border: '1px solid #ebeeef',
    boxShadow: 'none'
  }

  const matches = useMediaQuery('(min-width:500px)')

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <Container>
        <MaterialTable
          style={style}
          columns={columns}
          data={rest.data}
          title={title}
          components={{
            Header: props =>
              tableHeader ? tableHeader : <MTableHeader {...props} />,
            Groupbar: props => <></>,
            GroupRow: props => {
              return GroupRow ? GroupRow : <MTableGroupRow {...props} />
            },
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  labelRowsSelect="linhas"
                  labelRowsPerPage={matches ? 'Linhas por página' : ''}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} de ${count}`
                  }}
                />
              )
            },
            // Toolbar: props => (toolbar ? toolbar : <MTableToolbar {...props} />)
            Toolbar: props => {
              const { printIcon } = rest
              return printIcon ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flexGrow: 1 }}>
                    <MTableToolbar {...props} />
                  </div>
                  <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                    <IconPrint
                      style={{
                        width: '2.5rem',
                        height: '3rem',
                        color: theme.colors.secondary
                      }}
                      onClick={onPrint}
                    />
                  </div>
                </div>
              ) : (
                <MTableToolbar {...props} />
              )
            }
          }}
          options={{
            paging: pagination,
            showTitle: titleShow ? titleShow : false,
            toolbarButtonAlignment: 'right',
            tableLayout: 'auto',
            search: search,
            searchFieldVariant: 'outlined',
            // toolbar: toolbar ? true : false,
            draggable: false,
            grouping: isGroup,
            sorting: true,
            actionsColumnIndex: -1,
            rowStyle: {
              fontSize: '14px',
              fontWeight: 400,
            },
            headerStyle: {
              fontSize: 14,
              fontWeight: 700,
              paddingRight: 15,
              zIndex: 0,
              backgroundColor: '#F6F6F6',
              color: '#8C8C8C'
            },
            actionsCellStyle: {
              justifyContent: 'center'
            },
            ...rest.options
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: matches ? '{count} de {from}-{to}' : '',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Pesquisar'
            },
            header: {
              actions: titleAction ? titleAction : 'Ações'
            },
            body: {
              emptyDataSourceMessage: (
                <>
                  {!rest.isLoading && (
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                          height: '50%'
                        }}
                      >
                        <h1
                          style={{
                            padding: '0.2rem',
                            paddingTop: '1rem'
                          }}
                        >
                          Sem dados para exibir
                        </h1>
                      </div>
                    </div>
                  )}
                </>
              )
            }
          }}
          isLoading={rest.isLoading}
          actions={rest?.actions}
          {...rest}
        />
      </Container>
    </ThemeProvider>
  )
}

export const Container = styled.div`
  button:hover {
    background: transparent;
  }

  & .MuiTableHead-root > tr > th {
    background-color: #ffff;
  }

  & .MuiToolbar-root > div > h6 {
    font-size: 2rem;
    color: ${theme.colors.textSidebar};
  }

  & .MuiTableCell-root {
    padding: 8px 16px;
  }

  & .MuiTableCell-root > b {
    display: none;
  }
`
