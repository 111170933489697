import styled from "styled-components";
import Select from "react-select";
import { MdClose } from 'react-icons/md';
import theme from '../../../styles/theme';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;

  .mes {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;

    margin-bottom: 30px;

    select {
      width: 100px;
      text-align: center;
      padding: 5px;
    }

    label {
      font-weight: 600;
    }
  }

  .btnConfirm {
    background-color: var(--green-1);
    padding: 12px;
    border-radius: 8px;
    color: var(--white);
    margin-top: 8px;
    width: 100%;
    font-size: 16px;
  }

  .divSelectUser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: row;
    background-color: ${theme.colors.backgroundLight};

    .selectUser {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: ${theme.colors.backgroundLight};
      font-size: 14px;
    }
  }
`;

export const DivTopModal = styled.div``;

export const DivBottomModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    margin-bottom: 40px;
  }

  & > div {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .button {
    width: 70px;
    height: 30px;
    border-radius: 5px;
    color: white;

    font-weight: 600;
  }

  .notButton {
    background-color: #c20006;
    font-size: 16px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .mes {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .divDate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 60px;
    padding: 8px;

    border: 1px solid var(--color2);
    border-radius: 5px;
    padding-left: 10px;
    color: ${theme.colors.textSidebar};


   input {
      border: none;
      font-size: 14px;
      text-align: right;
      color: ${theme.colors.textSidebar};
      background-color: ${theme.colors.backgroundLight};
      :hover{
        cursor: pointer;
      }
      :focus{
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
      }
      
    }
  }
  .styledForm {
    width: 100%;
    padding: 18px;
  }

  .divAdm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 500px;
  }

  .formControl {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .divBtn {
    margin-top: 20px;
  }
`;

export const StyledSelectProject = styled(Select)`
  width: 100%;
  background: ${theme.colors.backgroundLight};

  :focus{
    border: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  .Select__control {
    border: none; // Remove a borda do controle, se necessário
    box-shadow: none; // Remove a sombra do controle, se necessário
  }

  .Select__indicators {
    display: none; // Oculta o contêiner de indicadores
  }
  .Select__dropdown-indicator {
    display: none;
  }
`;

//stilização header modal
export const ContainerClose = styled.div`
  cursor: pointer;
`
export const Header = styled.div`
  width: 100%;
  padding: 12px 12px;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 1px 16px 0px rgba(227, 227, 227, 0.50);
`

export const ContentDescriptionHeader = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`

export const BoxIconRotation = styled.div`
  background-color: ${props =>
    props.setBackgroundColor ? props.setBackgroundColor : theme.colors.info400};
  height: 36px;
  width: 36px;
  border-radius: 4px;

  transform: rotate(20deg);
  margin-right: 1rem;
`

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 8px;
  left: 22px;
  top: 16px;
  backdrop-filter: blur(8px);
  border: 2px solid #fff;
`
export const IconClose = styled(MdClose)`
  font-size: 24px;
  color: ${theme.colors.textSidebar};
`
export const TextHeader = styled.h3`
  font-weight: 600;
  color: #787878;
  font-size: 16px;
`
