export default function sumHoursByDay(apontamentos) {
  const horasPorDia = {};

  apontamentos.forEach((apontamento) => {
    const data = new Date(apontamento.dt_apontamento);
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    var hora = 0;
    const dataFormatada = `${dia.toString().padStart(2, "0")}/${mes
      .toString()
      .padStart(2, "0")}/${ano}`;

    const { hr_inicio, hr_fim } = apontamento;

    if (apontamento.is_ausente) {
    } else {
      const [horasIni, minutosIni] = hr_inicio.split(":").map(parseFloat);
      const [horasFim, minutosFim] = hr_fim.split(":").map(parseFloat);

      if (horasFim < horasIni) {
        if (minutosFim < minutosIni) {
          hora = horasFim + 23 - horasIni + (minutosFim + 60 - minutosIni) / 60;
        } else {
          hora = horasFim + 24 - horasIni + (minutosFim - minutosIni) / 60;
        }
      } else if (horasFim === horasIni) {
        if (minutosFim < minutosIni) {
          hora = (minutosFim + 60 - minutosIni) / 60;
        } else {
          hora = 0;
        }
        hora = (minutosFim - minutosIni) / 60;
      } else {
        if (minutosFim < minutosIni) {
          hora = horasFim - 1 - horasIni + (minutosFim + 60 - minutosIni) / 60;
        } else {
          hora = horasFim - horasIni + (minutosFim - minutosIni) / 60;
        }
      }
    }

    if (!horasPorDia[dataFormatada]) {
      horasPorDia[dataFormatada] = 0;
    }

    if (apontamento.is_ausente) {
      horasPorDia[dataFormatada] += 0;
    } else {
      horasPorDia[dataFormatada] += hora;
    }
  });
  return horasPorDia;
}
