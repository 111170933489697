import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import api from "../../../../services/api.js";
import * as React from "react";
import { StyledModalDelApt } from "./style.js";
import { AptContext } from "../../../../providers/AptContext/AptContext.jsx";
import { ModalEditAptContext } from "../../../../providers/ModalEditApt/ModalEditApt.jsx";
import { filterAptsDay, filterAptsMes } from "../../../../utils/filterApts.js";
import loadApts from "../../../../utils/loadApts.js";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../../../utils/clearUser.js";
import { useState } from "react";
import { css } from "@emotion/react";
import { FaTrash } from "react-icons/fa";
import * as S from "./style.js";
import theme from "../../../../styles/theme.js";
import { messageError, messageSuccess } from "../../../../utils/message.js";
import { ClipLoader } from "react-spinners";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: theme.colors.white,
  boxShadow: 24,
  borderRadius: 2,
};

export default function ModalConfirmDelApt({
  openModal,
  setOpenModal,
  id,
  date,
  setOpenModalEdit,
}) {
  const handleClose = () => setOpenModal(false);
  const { setApt, setApontamentosMes, apontamentosMes, mes, ano, user } =
    React.useContext(AptContext);

  const { setFilteredApt } = React.useContext(ModalEditAptContext);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("@token");
  const navigate = useNavigate();

  const deleteApt = () => {
    if (user.jira_token_api != null) {
      setIsLoading(true);
      api
        .delete(`/apontamentos/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (resp) => {
          const loadAptsData = async () => {
            const apts = await loadApts(token);
            setApt(apts);
            const filteredApts = filterAptsMes(apts, mes, ano);
            setApontamentosMes(filteredApts);
          };
          await loadAptsData();
          const updatedApt = apontamentosMes.filter((apt) => apt.id !== id);
          const filterApt = filterAptsDay(updatedApt, date);
          setFilteredApt(filterApt);
          messageSuccess("Apontamento deletado");
          handleClose();
          setOpenModalEdit(false);
        })
        .catch((err) => {
          if (
            JSON.parse(err.request.response).Erro ===
            "Token expirado ou inválido"
          ) {
            clearUser(navigate);
          } else {
            messageError(JSON.parse(err.request.response).Erro);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      messageError("Adicione seu Jira Token");
    }
  };

  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <S.Header>
            <S.ContentDescriptionHeader>
              <S.BoxIconRotation
                setBackgroundColor={theme.colors.error200}
              ></S.BoxIconRotation>
              <S.BoxIcon>
                <FaTrash color={theme.colors.error} size={16} />
              </S.BoxIcon>
              <S.TextHeader>{`Excluir apontamento`}</S.TextHeader>
            </S.ContentDescriptionHeader>
            <S.ContainerClose onClick={handleClose}>
              <S.IconClose />
            </S.ContainerClose>
          </S.Header>
          <StyledModalDelApt>
            <h2>Deseja excluir o apontamento?</h2>

            <div className="divBtns">
              {isLoading ? (
                <ClipLoader
                  css={override}
                  size={30}
                  color={"#000000"}
                  loading={isLoading}
                />
              ) : (
                <>
                  <button className="buttonCancel" onClick={handleClose}>
                    Cancelar
                  </button>
                  <button className="buttonRegister" onClick={deleteApt}>
                    Excluir
                  </button>
                </>
              )}
            </div>
          </StyledModalDelApt>
        </Box>
      </Modal>
    </div>
  );
}
