import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {ModalEditAptContext} from "../../../providers/ModalEditApt/ModalEditApt";
import {useContext} from "react";
import * as React from "react";
import ChildModal from "./ChildModal";
import {StyledModalEdit} from "./style";
import AptTable from "../../Table/AptTable";
import ModalChangeTask from "./ModalChangeTask";
import * as S from './style.js'
import theme from '../../../styles/theme.js'
import { FaSlidersH } from "react-icons/fa";
import ModalConfirmDelApt from "./ModalConfirmDelApt/index.jsx";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '80%',
  bgcolor: "var(--color1)",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  heigth: '558px'
};

export default function ModalEditApt() {
  const {openModalEditApt, setOpenModalEditApt, filteredApt, selectedApt, 
    setSelectedApt, openChildModal, setOpenChildModal, openModalDelApt, setOpenModalDelApt, idApt} =
    useContext(ModalEditAptContext);
  const handleClose = () => setOpenModalEditApt(false);


  const dataFormatada = () => {
    const date = new Date(filteredApt[0]?.dt_apontamento || "");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  filteredApt.sort(function (a, b) {
    var [horaA, minutoA] = a.hr_inicio.split(":");
    var [horaB, minutoB] = b.hr_inicio.split(":");

    if (parseInt(horaA) === parseInt(horaB)) {
      return parseInt(minutoA) - parseInt(minutoB);
    } else {
      return parseInt(horaA) - parseInt(horaB);
    }
  });

  const date = selectedApt.dt_apontamento?.split("T")[0].slice(8);


  return (
    <div>
      <ChildModal
        selectedApt={selectedApt}
        openChildModal={openChildModal}
        setOpenChildModal={setOpenChildModal}
      />
      <ModalChangeTask selectedApt={selectedApt} />
      <ModalConfirmDelApt
        openModal={openModalDelApt}
        setOpenModal={setOpenModalDelApt}
        id={idApt}
        date={date}
        setOpenModalEdit={setOpenChildModal}
      /> 
      <Modal
        open={openModalEditApt}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={{...style}}>
          <StyledModalEdit>
            <S.Header>
              <h1>Teste</h1>
              <S.ContentDescriptionHeader>
                <S.BoxIconRotation
                setBackgroundColor={theme.colors.warning}
                ></S.BoxIconRotation>
                <S.BoxIcon>
                  <FaSlidersH color={theme.colors.warning} size={16} />
                </S.BoxIcon>
                <S.TextHeader>{`Editar Tarefas (${selectedApt.task_key})`}</S.TextHeader>
              </S.ContentDescriptionHeader>
              <S.ContainerClose onClick={handleClose}>
                <S.IconClose />
              </S.ContainerClose>
            </S.Header>
            {filteredApt.length > 0 ? (
              <>
                <h2>{dataFormatada()}</h2>
                <div></div>
                <div className="divApts">
                  <AptTable
                    apontamentos={filteredApt}
                    setSelectedApt={setSelectedApt}
                    setOpenChildModal={setOpenChildModal}
                  />
                </div>
              </>
            ) : (
              handleClose()
            )}
          </StyledModalEdit>
        </Box>
      </Modal>
    </div>
  );
}
