import styled from 'styled-components'


export const Container = styled.div`
  .content {
    padding: 32px 56px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 100vh;
    display: grid;
    transition: all 0.4s ease;
    grid-template:
      'sidenav content' 1fr
      / ${props => (props.menuExpanded ? '27rem' : '6rem')} 1fr;

    .content {
      grid-area: content;
      width: 100%;
      z-index: 0;
      /* background-color: red; */
      /* padding: 2em 3.5em; */
    }
  }
  content {
    /* padding: 1.8rem; */

    @media (min-width: 1024px) {
      /* padding: 2rem 5rem; */
    }
  }

  .menu-open & {
    grid-template:
      'sidenav content' 1fr
      / 1fr;
  }
`

export const ContainerTwo = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .content {
    padding: 1.8rem;

    @media (min-width: 1024px) {
      padding: 2rem 5rem;
    }
  }
`
