import { Box, FormControl, Modal } from "@mui/material";
import { SiMicrosoftexcel } from "react-icons/si";
import { ContainerModal, DivTopModal, DivBottomModal } from "./style";
import { useState } from "react";
import api from "../../../services/api";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as S from "./style.js";
import theme from "../../../styles/theme.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: theme.colors.backgroundLight,
  boxShadow: 24,
  height: 300,
  display: "flex",
  gap: 4,
  flexDirection: "column",
  textAlign: "center",
  borderRadius: "5px",
  border: "none",
};

const ModalExportExcel = ({ openModalExport, setOpenModalExport }) => {
  const token = localStorage.getItem("@token");
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = yup.object().shape({
    inicio: yup.string().required("Tarefa Obrigatória"),
    fim: yup.date().required("Data obrigatória"),
  });

  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleDownload = (data) => {
    const inicioFormatted = new Date(data.inicio).toISOString().split("T")[0];
    const fimFormatted = new Date(data.fim).toISOString().split("T")[0];
    const ds = `${inicioFormatted} - ${fimFormatted}`;
    setIsLoading(true);
    api({
      url: `/apontamentos/${inicioFormatted}/${fimFormatted}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob", // important
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${ds}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);

        URL.revokeObjectURL(href);

        setOpenModalExport(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          toast.error("Token expirado");
          navigate("/");
        }

        try {
          toast.error(JSON.parse(err.request.response).Erro);
        } catch (error) {
          toast.error("Não foram encontrados apontamentos");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  return (
    <Modal
      open={openModalExport}
      onClose={() => setOpenModalExport(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className="boxModal" sx={style}>
        <ContainerModal>
          <S.Header>
            <S.ContentDescriptionHeader>
              <S.BoxIconRotation
                setBackgroundColor={theme.colors.primaryLight}
              ></S.BoxIconRotation>
              <S.BoxIcon>
                <SiMicrosoftexcel
                  color={theme.colors.primary500}
                  size={16}
                ></SiMicrosoftexcel>
              </S.BoxIcon>
              <S.TextHeader>Exportar Todos Dados</S.TextHeader>
            </S.ContentDescriptionHeader>
            <S.ContainerClose onClick={() => setOpenModalExport(false)}>
              <S.IconClose />
            </S.ContainerClose>
          </S.Header>
          <DivBottomModal>
            <form
              className="styledForm"
              onSubmit={handleSubmit(handleDownload)}
            >
              <FormControl className="formControl">
                <div className="divDate">
                  <label>Inicio</label>
                  <input
                    id="inicio"
                    name="inicio"
                    type="date"
                    {...register("inicio")}
                  />
                </div>
                <div className="divDate">
                  <label>Fim</label>
                  <input id="fim" name="fim" type="date" {...register("fim")} />
                </div>
                <div className="divBtn">
                  {isLoading ? (
                    <ClipLoader
                      css={override}
                      size={30}
                      color={"#000000"}
                      loading={isLoading}
                    />
                  ) : (
                    <button className="btnConfirm" type="submit">
                      Download
                    </button>
                  )}
                </div>
              </FormControl>
            </form>
          </DivBottomModal>
        </ContainerModal>
      </Box>
    </Modal>
  );
};

export default ModalExportExcel;
