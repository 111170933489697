import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { StyledModalForm } from "./style";
import loadApts from "../../../../utils/loadApts";
import { AptContext } from "../../../../providers/AptContext/AptContext";
import { filterAptsDay, filterAptsMes } from "../../../../utils/filterApts";
import { ModalEditAptContext } from "../../../../providers/ModalEditApt/ModalEditApt";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../../../utils/clearUser";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { retrabalhoLogic, validacaoPBI } from "../../../../utils/apotamentos";
import theme from "../../../../styles/theme";
import * as S from "./style";
import { FaArchive } from "react-icons/fa";
import { LabeledStyledInputComponent } from "../../../StyledInput";
import { LabeledStyledTextArea } from "../../../StyledTextArea";
import { SwitchButton } from "../../../SwitchButton";
import { messageError, messageSuccess } from "../../../../utils/message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 518,
  bgcolor: theme.colors.white,
  borderRadius: 2,
  boxShadow: 24,
};

export default function ChildModal({
  selectedApt,
  openChildModal,
  setOpenChildModal,
}) {
  const date = selectedApt.dt_apontamento?.split("T")[0].slice(8);

  const handleClose = () => {
    setOpenChildModal(false);
  };
  const { setApt, setApontamentosMes, apontamentosMes, mes, ano, user } =
    useContext(AptContext);

  const { setFilteredApt } = useContext(ModalEditAptContext);
  const [apontamento, setApontamento] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("@token");

  const formSchema = yup.object().shape({
    dt_apontamento: yup.date().required("Data obrigatória"),
    hr_inicio: yup.string().required("Hora inicial obrigatória"),
    hr_fim: yup.string().required("Hora final obrigatória"),
    ds_apontamento: yup.string().required("Descrição obrigatória"),
    is_retrabalho: yup.boolean().required(),
    is_bug: yup.boolean().required(),
    ds_justificativa_retrabalho: yup
      .string()
      .when("is_retrabalho", {
        is: true,
        then: (schema) => schema.required("Justificativa obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      })
      .when("is_bug", {
        is: true,
        then: (schema) => schema.required("Justificativa obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      }),
    ao_solicita_pbi: yup.string().default("N"),
    pbi_key: yup.string().when("ao_solicita_pbi", {
      is: "S",
      then: (schema) => schema.required("PBI key obrigatória"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    let is_retrabalho_value = selectedApt?.id_tarefa_pai;
    let is_bug = selectedApt?.type?.toLowerCase().includes("bug");

    if (selectedApt?.is_retrabalho) {
      is_retrabalho_value = true;
    } else {
      is_retrabalho_value = false;
    }

    setApontamento(selectedApt);
    setValue("dt_apontamento", selectedApt?.dt_apontamento?.substring(0, 10));
    setValue("hr_inicio", selectedApt?.hr_inicio);
    setValue("hr_fim", selectedApt?.hr_fim);
    setValue("ds_apontamento", selectedApt?.ds_apontamento);
    setValue("is_retrabalho", is_retrabalho_value);
    setValue("is_bug", is_bug);
    setValue(
      "ds_justificativa_retrabalho",
      selectedApt?.ds_justificativa_retrabalho
    );

    if (selectedApt?.pbi_key) {
      setValue("ao_solicita_pbi", "S");
      setValue("pbi_key", selectedApt?.pbi_key);
    } else {
      setValue("ao_solicita_pbi", "N");
    }
  }, [selectedApt, setValue]);

  const submitForm = (data) => {
    data["id_operador_alteracao"] = user.id;
    if (data["hr_fim"] <= data["hr_inicio"]) {
      messageError("Hora final menor ou igual à Hora Inicial");
    } else if (user.jira_token_api != null) {
      setIsLoading(true);

      const isPBIValido = validacaoPBI(data);

      if (!isPBIValido) {
        return;
      }

      if (!data["is_retrabalho"]) {
        const isInvalid = retrabalhoLogic(data, setIsLoading);

        if (isInvalid) {
          return;
        }
      }

      if (!data["is_bug"] && !data["is_retrabalho"]) {
        data["ds_justificativa_retrabalho"] = undefined;
      }

      api
        .patch(`/apontamentos/${apontamento.id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (resp) => {
          const loadAptsData = async () => {
            const apts = await loadApts(token);
            setApt(apts);
            const filteredApts = filterAptsMes(apts, mes, ano);
            setApontamentosMes(filteredApts);
          };
          await loadAptsData();
          const updatedApt = apontamentosMes.map((apt) =>
            apt.id === resp.data.data.id ? resp.data.data : apt
          );
          const filterApt = filterAptsDay(updatedApt, date);
          setFilteredApt(filterApt);
          messageSuccess("Apontamento editado");
          handleClose();
        })
        .catch((err) => {
          if (
            JSON.parse(err.request.response).Erro ===
            "Token expirado ou inválido"
          ) {
            clearUser(navigate);
          } else {
            messageError(JSON.parse(err.request.response).Erro);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      messageError("Adicione seu Jira Token");
    }
  };
  const override = css`
    display: block;
    margin: 10 auto;
    border-color: red;
  `;

  useEffect(() => {
    const errorsArr = Object.entries(errors);

    if (Boolean(errorsArr[0])) {
      messageError(errorsArr[0][1].message);
    }
  }, [errors]);

  // const deleteApt = () => {
  //   if (user.jira_token_api != null) {
  //     api
  //       .delete(`/apontamentos/${apontamento.id}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then(async (resp) => {
  //         const loadAptsData = async () => {
  //           const apts = await loadApts(token);
  //           setApt(apts);
  //           const filteredApts = filterAptsMes(apts, mes, ano);
  //           setApontamentosMes(filteredApts);
  //         };
  //         await loadAptsData();
  //         const updatedApt = apontamentosMes.filter(
  //           (apt) => apt.id !== apontamento.id
  //         );
  //         const filterApt = filterAptsDay(updatedApt, date);
  //         setFilteredApt(filterApt);
  //         messageSuccess("Apontamento deletado");
  //         handleClose();
  //       });
  //   } else {
  //     messageError("Adicione seu Jira Token");
  //   }
  // };

  return (
    <>
      <Modal
        open={openChildModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <S.Header>
            <S.ContentDescriptionHeader>
              <S.BoxIconRotation
                setBackgroundColor={theme.colors.secondaryLight}
              ></S.BoxIconRotation>
              <S.BoxIcon>
                <FaArchive color={theme.colors.secondary} size={16} />
              </S.BoxIcon>
              <S.TextHeader>{`Editar Tarefas (${selectedApt.task_key})`}</S.TextHeader>
            </S.ContentDescriptionHeader>
            <S.ContainerClose onClick={handleClose}>
              <S.IconClose />
            </S.ContainerClose>
          </S.Header>
          <StyledModalForm onSubmit={handleSubmit(submitForm)}>
            <LabeledStyledInputComponent
              label="Data"
              id="dt_apontamento"
              name="dt_apontamento"
              type="date"
              // defaultValue={currentDate}
              register={register("dt_apontamento")}
            />
            <div className="divBothHours">
              <LabeledStyledInputComponent
                label="Horário de Início"
                id="hr_inicio"
                name="hr_inicio"
                type="time"
                register={register("hr_inicio")}
                className="style-input"
                placeholder="00:00"
              />
              <LabeledStyledInputComponent
                label="Horário de Término"
                id="hr_fim"
                name="hr_fim"
                type="time"
                register={register("hr_fim")}
                className="style-input"
                placeholder="00:00"
              />
            </div>
            <LabeledStyledTextArea
              id="ds_apontamento"
              name="ds_apontamento"
              label="Descrição"
              placeholder="Escreva a descrição aqui..."
              defaultValue={watch("ds_apontamento")}
              type="text"
              register={register("ds_apontamento")}
            />

            <div className="divModalBtns">
              <div className="divCheckMarks">
                <div className="divSwitchLabel">
                  <SwitchButton
                    {...register("is_retrabalho")}
                    value={"is_retrabalho"}
                    name={"is_retrabalho"}
                    onChange={(e, c) => {
                      setValue("is_retrabalho", c);
                    }}
                    checked={watch("is_retrabalho")}
                  />
                  <label style={{ color: theme.colors.pink500 }}>
                    Retrabalho
                  </label>
                </div>

                <div className="divSwitchLabel">
                  <SwitchButton
                    {...register("ao_solicita_pbi_checkbox")}
                    value={"ao_solicita_pbi_checkbox"}
                    name={"ao_solicita_pbi_checkbox"}
                    onChange={(e, c) => {
                      if (c) {
                        setValue("ao_solicita_pbi", "S");
                      } else {
                        setValue("ao_solicita_pbi", "N");
                        setValue("pbi_key", null);
                      }
                    }}
                    checked={watch("ao_solicita_pbi") === "S"}
                  />
                  <label>Nº PBI</label>
                </div>
              </div>
              {(watch("is_retrabalho") || watch("is_bug")) && (
                <LabeledStyledTextArea
                  label="Justificativa de retrabalho"
                  id="ds_justificativa_retrabalho"
                  name="ds_justificativa_retrabalho"
                  placeholder="Justificativa retrabalho"
                  type="text"
                  register={register("ds_justificativa_retrabalho")}
                />
              )}
              {watch("ao_solicita_pbi") === "S" && (
                <LabeledStyledInputComponent
                  label="N° PBI"
                  id="pbi_key"
                  register={register("pbi_key")}
                  placeholder="N° PBI"
                  type="text"
                />
              )}
              <div className="divBtns">
                {isLoading ? (
                  <ClipLoader
                    css={override}
                    size={30}
                    color={"#000000"}
                    loading={isLoading}
                  />
                ) : (
                  <>
                    <button onClick={handleClose} className="buttonCancel">
                      Cancelar
                    </button>
                    <button className="buttonRegister" type="submit">
                      Salvar
                    </button>
                  </>
                )}
                {/* <button
                      type="button"
                      onClick={() => setOpenModalDelApt(true)}
                      className="deleteBtn"
                    >
                      Deletar
                    </button> */}
              </div>
            </div>
          </StyledModalForm>
        </Box>
      </Modal>
    </>
  );
}
