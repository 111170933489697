import {styled} from "styled-components";

export const StyledTr = styled.tr`
  color: ${props => props.is_retrabalho ? "red" : "black"};

  td {
    border: 2px solid var(--color1);
    cursor: pointer;
  }
  
  .td1.hovered,
  .td2.hovered {
    background-color: var(--color5);
    color: white;
  }
  .tdDescription {
    /* width: 500px; */
  }
  .tdJira {
    width: 110px;
  }
`;
