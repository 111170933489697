import React, { HTMLAttributes, useState } from "react";

import * as S from "../styles/pages/_layout";

import Sidebar from "../components/Sidebar";
// import PageHeader from "../components/PageHeader";
import ButtonTop from "../components/ButtonTop";

// interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
//   withoutPadding?: boolean;
//   noBackButton?: boolean;
//   hidden?: boolean;
// }

const Layout = ({
  children,
  noBackButton,
  hidden,
  ...rest
}) => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  return (
    <>
      {hidden ? (
        <S.ContainerTwo
          withoutPadding={rest.withoutPadding}
          menuExpanded={menuExpanded}
        >
          {/* <PageHeader noBackButton={noBackButton} /> */}
          <Sidebar
            menuExpanded={menuExpanded}
            setMenuExpanded={setMenuExpanded}
            hidden={hidden}
          />
          <div className="content">{children}</div>
        </S.ContainerTwo>
      ) : (
        <S.Container menuExpanded={menuExpanded}>
          {/* <PageHeader noBackButton={noBackButton} /> */}
          <Sidebar
            menuExpanded={menuExpanded}
            setMenuExpanded={setMenuExpanded}
          />
          <div className="content">{children}</div>
        </S.Container>
      )}
      <ButtonTop />
    </>
  );
};

export default Layout;
