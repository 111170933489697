const compararDatas = (a, b) => {
  const dataA = new Date(a.created_at);
  const dataB = new Date(b.created_at);
  // const dataA = new Date(a.dt_apontamento);
  // const dataB = new Date(b.dt_apontamento);
  if (dataA < dataB) {
    return 1;
  } else if (dataA > dataB) {
    return -1;
  } else {
    return 0;
  }
};

export default compararDatas;
